<template>
  <div class="home">
    <!-- <div class="center-logo">
      <img src="@/assets/readycall_logo.png" alt="" />
    </div> -->
    <!-- <div class="left-logo"><img src="@/assets/logo_1.png" alt="" /></div>
    <div class="right-logo"><img src="@/assets/logo_2.png" alt="" /></div> -->
    <h1><img src="@/assets/main_title.png" alt="" /></h1>
    <p>
      10월 3일 목요일, 개천절에 열리는<br />
      국내 최대의 다음세대 크리스천 페스티벌<br />
      <b style="font-size:15px; font-weight: bold; color: #222;"
        >READYCALL (레디컬)</b
      >
      과 함께하는 <br />나와 어울리는 성경 속 식물 찾기 테스트!
    </p>
    <div class="btn-area">
      <button @click="$router.push('/test')">테스트 시작하기</button>
    </div>
    <div class="about" @click="about = true">
      <span> 누가 이 페이지를 만들었나요? </span>
    </div>
    <div class="ani-box">
      <img src="@/assets/main_ani_bg.png" alt="" />
      <div class="ani-img1">
        <img src="@/assets/main_ani_1.png" alt="" />
      </div>
      <div class="ani-img2">
        <img src="@/assets/main_ani_2.png" alt="" />
      </div>
    </div>
    <transition name="slide-up">
      <div class="credit" v-if="about">
        <div class="credit-desc">
          <div class="credit-header">
            <button @click="about = false">
              <svg
                height="329pt"
                viewBox="0 0 329.26933 329"
                width="329pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
                />
              </svg>
            </button>
            <h2>누가 이 페이지를 만들었나요?</h2>
          </div>
          <div class="credit-bg">
            <div class="credit-content">
              <a
                href="https://loaisloveoneanother.com/product/detail.html?product_no=3350"
                target="_blank"
              >
                <img src="@/assets/banner_readycall.png" />
              </a>
              <p>
                <img
                  src="@/assets/logo_loa.png"
                  style="width: 45px; margin-right: 15px;"
                />
                <img
                  src="@/assets/logo_1.png"
                  style="width: 80px; margin-right: 15px;"
                />
                <img
                  src="@/assets/logo_samaria.png"
                  style="width: 100px"
                  alt=""
                />
              </p>
              <p style="word-break: keep-all;">
                READY CALL(레디컬)은 복음 적 사명을 함께 꿈꾸며 살아 내고 있는
                크리스천 브랜드, 리더십, 인플루언서, 교육기관, 선교단체 및
                NGO들이 한 자리에 모여 서로의 비전을 공유하고 협력하는 국내 최대
                규모의 다음세대 크리스천 페스티벌입니다.
              </p>
              <div class="about-action-button">
                <a
                  target="_blank"
                  href="https://loaisloveoneanother.com/product/detail.html?product_no=3350"
                >
                  <button
                    class="action-button"
                    style="position:relative; z-index: 5000;"
                  >
                    페스티벌 신청하러가기
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      style="vertical-middle:middle;"
                    >
                      <path d="M9 18l6-6-6-6" />
                    </svg>
                  </button>
                </a>
              </div>
              <!-- <img src="@/assets/about.png" alt="" /> -->
              <!-- <img src="@/assets/logo" alt=""> -->
              <div class="ani-box">
                <img src="@/assets/main_ani_bg.png" alt="" />
                <div class="ani-img1">
                  <img src="@/assets/main_ani_1.png" alt="" />
                </div>
                <div class="ani-img2">
                  <img src="@/assets/main_ani_2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!-- <a href="https://www.instagram.com/firewood.z/" target="_blank"
            ><img src="@/assets/credit.jpg" alt=""
          /></a> -->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      about: false,
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.$store.commit("setPartner", this.$route.query.type);
    } else {
      this.$store.commit("setPartner", null);
    }
  },
  computed: {
    partner() {
      return this.$route.query.type ? this.$route.query.type : null;
    },
  },
};
</script>

<style lang="scss">
.home {
  /* background: url(https://lh3.googleusercontent.com/Z5HrRVYCfiHinxs6XgJewB0q1fNkE_tKUh35SxglJJsWNiiFSJkiLosNrimN6OjI6ZHXzbJuurKT6f7FWkjaJvUOBGPUCIR36ejQno4ySbwk4h6wdKPREWsC37apP6WQ4lFQEHQF7SxWzGIu7P-VPcG9ZW9zB1NkhS8-56MyF-mch1L2fcFoINPeGseivA-ef6y_1PtD2RZkcFqXvbhhHkgr3roZFzScG6cb80Y4tMz5hVLhU-ujge7-1_dHdgnqlD1dbhNUiSgoWgcEEolSEe0gFdXBDhlh790r-1utl4OcCTJDiu4lHCmSv72khwPdkfqD1rVqgxaiqeeUuReehp3h5A5tdCRXshXOmv7jU-tQYgg3zeAVBtc02u_O2bfoWeW63QE4ubKJnSh0p5ez5V2Aih88Tf2kgKYHscMl84Zdu9mCjh7R_ddlvfOAN-j0KVPQELWMjAML5RBThe7PIg8qVY9ip5VwXEOGDEoEf0UvO-66xfp4E9pfKyTyXbAT6-LVZbk3D12NdFJstvVhdKtvCwQSlYaoQzZMIZHnhe2mkH2NmEJ_dqNvG6nfEJr4mANCe8K2uw3no0I-QNyyYSLuH-ZLoS7AU3k2E9C2IETKiL9bal6BBiTfxRAnTkxWTxWWmcd-Vd8JGKn0D_JUsPRO-ZBNXLFMtVjfRvGHQvEOAoc0vzU2AT5lIPDleg=w453-h730-no?authuser=0); */
  background: url(~@/assets/main-bg.png);
  overflow: hidden;
  max-width: 450px;
  margin: 0 auto;
  padding-top: 60px;
  background-size: cover;
  min-height: 100vh;
  position: relative;
  .left-logo {
    position: absolute;
    left: 10px;
    top: 17px;
    img {
      width: 75px;
    }
  }
  .center-logo {
    position: absolute;
    left: 50%;
    top: 17px;
    transform: translate(-50%, 0);
    img {
      width: 100px;
    }
  }
  .right-logo {
    position: absolute;
    right: 10px;
    top: 15px;
    img {
      width: 120px;
    }
  }
  h1 {
    text-align: center;
    /* padding-bottom: 10px; */
    img {
      width: 250px;
    }
  }
  p {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    color: #555;
  }

  .btn-area {
    text-align: center;
    padding-top: 50px;
    position: relative;
    z-index: 6;
    button {
      background: #fff;
      border-radius: 30px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 20px;
      display: inline-block;
      border: 0;
      box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.2);
      width: 280px;
      font-weight: bold;
    }
  }
  .about {
    text-align: center;
    padding-top: 20px;
    position: relative;
    z-index: 5;
    span {
      text-align: center;
      text-decoration: underline;
      font-size: 12px;
      margin: 0 auto;
      font-weight: 100;
    }
  }
  .ani-box {
    padding: 32px 32px 16px;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1;
    img {
      width: 100%;
    }
    .ani-img1 {
      padding: 32px 32px 16px;
      position: absolute;
      height: 100%;
      left: 2px;
      top: 0;
      z-index: -1;
      img {
        transform: rotate(0deg);
        animation: aniImg1 3s ease-in-out infinite alternate;
        transform-origin: center bottom;
      }
    }
    .ani-img2 {
      padding: 32px 32px 16px;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0px;
      z-index: -2;
      img {
        transform: rotate(0deg);
        animation: aniImg2 5s ease-in-out infinite alternate;
        /* animation-delay: 0.5; */
        transform-origin: 300px 400px;
      }
    }
  }
}
.credit {
  z-index: 999;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  /* padding-top: 200px; */
  img {
    width: 100%;
    vertical-align: middle;
  }
  .credit-desc {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    padding-top: 200px;
    background: rgba(0, 0, 0, 0.5);
    overflow: auto;
    height: 100%;
    .credit-bg {
      background: url(~@/assets/main-bg.png);
      position: relative;
      height: 100%;
      padding: 40px;

      .credit-content {
        background: #fff;
        /* max-height: 400px; */
        min-height: 500px;
        box-shadow: 4px 4px 10px 4px rgba(0, 0, 0, 0.15);
        padding-top: 20px;
        border-radius: 20px;
        position: relative;
        padding-bottom: 10px;
        overflow: hidden;
        p {
          font-size: 11px;
          color: #555;
          padding: 0 20px;
          padding-top: 30px;
          line-height: 17px;
        }
        .logos {
          text-align: center;

          img {
            width: 100px;
          }
        }

        .banner {
          padding-top: 30px;
        }
      }
      .ani-box {
        width: 250px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .credit-header {
      background: #fff;
      border-radius: 20px 20px 0 0;
      height: 50px;
      display: flex;
      padding: 0 16px;
      position: relative;
      button {
        background: transparent;
        border: 0;
        align-items: center;
      }
      svg {
        width: 20px;
        height: auto;
        fill: #555;
      }
      h2 {
        font-size: 14px;
        color: #333;
        text-align: center;
        line-height: 52px;
        height: 50px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

@keyframes aniImg1 {
  0% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(2deg);
  }
}
@keyframes aniImg2 {
  0% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.5s;
}
.slide-up-enter, .slide-up-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate(0, 50%);
  opacity: 0;
}
.about-action-button {
  text-align: center;
  button {
    background: #d8d8d8;
    font-size: 14px;
    padding: 0 20px;
    height: 30px;
    line-height: 20px;
    font-weight: bold;
    margin-top: 20px;
    border: 0;
    border-radius: 10px;
    outline: 0;
    cursor: pointer;
  }
}
</style>
