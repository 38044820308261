<template>
  <div class="result-page">
    <h2><img src="@/assets/mbti-title.png" alt="" /></h2>
    <!-- <div>
			당신은
		</div> -->

    <div class="object-img">
      <img :src="objectImg" alt="" />
    </div>

    <div class="object-title">
      <p>
        <span>{{ title }}</span>
      </p>
      <img :src="titleName" alt="" />
    </div>

    <div :class="`result-desc ${type}`">
      <div v-html="description"></div>
    </div>

    <div class="result-recommend">
      <ul>
        <li class="verse">
          <div class="box">
            <div class="box-title">
              <!-- <img src="@/assets/i_verse.png" alt="" /> -->
              <p v-html="result.verse.label"></p>
            </div>
            <p class="verse-desc" v-html="result.verse.title"></p>
          </div>
        </li>
        <li>
          <div class="box" style="padding: 10px 0 5px">
            <a
              href="https://loaisloveoneanother.com/product/detail.html?product_no=3350"
              target="_blank"
              @click="onClickReadyCallBanner"
              ><img src="@/assets/banner_readycall.png" style="width: 100%;"
            /></a>
          </div>
        </li>

        <li>
          <div class="box">
            <div class="box-title" style="background: #3f927b">
              <!-- <img src="@/assets/i_verse.png" alt="" /> -->
              <p><b>READYCALL(레디컬)</b> 행사란?</p>
            </div>

            <p style="padding: 0 10px;word-break: keep-all;">
              READY CALL(레디컬)은 복음 적 사명을 함께 꿈꾸며 살아 내고 있는
              크리스천 브랜드, 리더십, 인플루언서, 교육기관, 선교단체 및 NGO들이
              한 자리에 모여 서로의 비전을 공유하고 협력하는
              <br />국내 최대 규모의 다음세대 크리스천 페스티벌입니다.
            </p>
            <div class="submit-button">
              <a
                href="https://loaisloveoneanother.com/product/detail.html?product_no=3350"
                target="_blank"
                @click="onClickAboutReadyCall"
              >
                <button>
                  무료 신청하러가기
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    style="vertical-middle:middle;"
                  >
                    <path d="M9 18l6-6-6-6" />
                  </svg>
                </button>
              </a>
              <b
                style="display:block; font-size:10px; color: #777; font-weight: normal; margin-top: 6px"
                >지금무료 사전신청 하시면 다양한 혜택을 드려요!</b
              >
            </div>
          </div>
        </li>

        <li class="video">
          <div class="box">
            <div class="box-title">
              <!-- <img src="@/assets/i_video.png" alt="" /> -->
              <p v-html="result.video.label"></p>
            </div>
            <!-- <p class="video-title" v-html="result.video.title"></p> -->
            <iframe
              width="80%"
              height="200"
              :src="result.video.link"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </li>
        <li class="goods">
          <div class="box">
            <div class="box-title">
              <!-- <img src="@/assets/i_goods.png" alt="" /> -->
              <p v-html="result.goods.label"></p>
            </div>
            <div class="goods-desc">
              <a
                :href="result.goods.link"
                target="_blank"
                @click="onClickGoods"
              >
                <div class="img-area">
                  <img :src="result.goods.image" alt="" />
                </div>
                <div class="text-area">
                  <p>
                    <span v-html="`'${result.goods.title}'`"></span>
                    <button>
                      굿즈 보러가기
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#000000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        style="vertical-middle:middle;"
                      >
                        <path d="M9 18l6-6-6-6" />
                      </svg>
                    </button>
                    <b
                      style="display:block; font-size:10px; color: #777; font-weight: normal; margin-top: 6px"
                      >Feat. 사랑을 전하는<br />크리스천 굿즈 플랫폼
                      ‘로아스토어’</b
                    >
                  </p>
                </div>
              </a>
            </div>
          </div>
        </li>

        <li>
          <div class="box">
            <div class="box-title">
              <p><b>사마리안퍼스 코리아 OCC</b> 후원하기</p>
            </div>
            <img
              src="@/assets/samaria.png"
              style="width: 80%; margin-bottom: 16px;"
            />

            <p style="padding: 0 10px; word-break: keep-all;">
              전 세계 어린이에게 사랑이 담긴 선물과 함께 복음을 전하는
              사마리안퍼스의 해외 어린이 전도 사역, OCC선물상자!
            </p>

            <a
              target="_blank"
              href="https://samaritanspurse.or.kr"
              @click="onClickSamaria"
            >
              <button class="action-button">
                OCC 선물상자 후원하기
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style="vertical-middle:middle;"
                >
                  <path d="M9 18l6-6-6-6" />
                </svg>
              </button>
            </a>
          </div>
        </li>
        <!-- <li class="partner">
          <template v-if="getPartnerType">
            <div class="box">
              <div class="box-title">
                <img src="@/assets/i_love.png" alt="" />
                <p v-html="partner.partner.label"></p>
              </div>
              <div class="partner-desc">
                <div class="left-box">
                  <img :src="objectImg" alt="" />
                </div>
                <div class="right-box">
                  <img :src="partnerImg" alt="" />
                </div>
              </div>
              <div class="partner-progress">
                <span :style="{ width: `${partnerPercent.percent}%` }">
                  <b>{{ partnerPercent.percent }}%</b>
                </span>
              </div>
              <p>{{ partnerPercent.text }}</p>
            </div>
          </template>
          <template v-else>
            <div class="box">
              <div class="box-title">
                <img src="@/assets/i_love.png" alt="" />
                <p>다른 사람과 결과를 공유해보세요!</p>
              </div>

              <p>공유를 통해 상대방과 나의 <b>조화도</b>를 알 수 있어요!</p>
            </div>
          </template>
        </li> -->
      </ul>
    </div>
    <div class="btn-area">
      <button @click="share">
        내 결과 공유하기
        <!-- <div class="up-guide">
          공유하기를 통해 상대방과 <br />내 유형의 조화를 알 수 있어요!
        </div> -->
      </button>
      <button @click="retry">테스트 다시하기</button>
      <button @click="download">
        이미지 저장하기
        <div class="down-guide">
          이미지 저장을 통해<br />인스타 스토리에 인증해보세요!
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import getParnter from "../test/utils";
import { mapGetters } from "vuex";
export default {
  name: "Result",
  methods: {
    onClickReadyCallBanner() {
      console.log(`Result/click/banner/readycall`);
      this.$gtag.event(`Result/click/banner/readycall`);
    },
    onClickAboutReadyCall() {
      console.log(`Result/click/about/readycall`);
      this.$gtag.event(`Result/click/about/readycall`);
    },
    onClickGoods() {
      console.log(`Result/click/goods/${this.result.goods.title}`);
      this.$gtag.event(`Result/click/goods/${this.result.goods.title}`);
    },
    onClickSamaria() {
      console.log(`Result/click/samaria`);
      this.$gtag.event(`Result/click/samaria`);
    },
    retry() {
      this.$gtag.event(`Result/click/retry`);
      this.$store.state.type = "";
      this.$router.push("/test");
    },
    share() {
      this.$gtag.event(`Result/click/share`);
      const url = `https://ch-mbti.com?type=${this.getType}&by=k`;
      /* eslint-disable */
      Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: "성경 속 식물로 알아보는 크리스천 MBTI!",
          description: this.result.kakao,
          imageUrl: `https://ch-mbti.com/kakao/${this.result.type}.png`,
          link: {
            webUrl: url,
            mobileWebUrl: url,
          },
        },
        buttons: [
          {
            title: "테스트 바로가기",
            link: {
              webUrl: url,
              mobileWebUrl: url,
            },
          },
        ],
      });
    },
    download() {
      window.open(require(`@/assets/img-${this.getType}.jpg`));
    },
  },
  computed: {
    ...mapGetters({
      getType: "getType",
      result: "getResult",
      getPartnerType: "getPartnerType",
      partner: "getPartnerResult",
    }),
    objectImg() {
      return Boolean(this.getType)
        ? require(`@/assets/${this.getType}.png`)
        : "";
    },
    description() {
      return this.result ? this.result.description : "";
    },
    name() {
      return this.result ? this.result.name : "";
    },
    title() {
      return this.result ? this.result.title : "";
    },
    titleName() {
      return Boolean(this.getType)
        ? require(`@/assets/ob-name-${this.result.type}.png`)
        : "";
    },
    type() {
      if (this.getType) {
        return this.getType.substr(0, 1) === "i" ? "i" : "e";
      }
      return "";
    },
    partnerImg() {
      return Boolean(this.getPartnerType)
        ? require(`@/assets/${this.getPartnerType}.png`)
        : "";
    },
    partnerPercent() {
      return getParnter(this.getType, this.getPartnerType);
    },
  },
  created() {
    if (!this.getType) {
      this.$router.push("/");
      return false;
    }
  },
  beforeDestroy() {
    this.$store.state.type = "";
  },
};
</script>

<style lang="scss" scoped>
.result-page {
  background: url(~@/assets/result-bg.png) repeat-y 0 0;
  background-size: contain;
  min-height: 200vh;
  width: 100%;
  overflow: hidden;
  max-width: 450px;
  margin: 0 auto;
  h2 {
    text-align: center;
    padding-top: 30px;
    img {
      width: 160px;
    }
  }
  .object-img {
    min-height: 200px;
    line-height: 200px;
    padding: 30px 20px 20px;
    text-align: center;
    position: relative;
    opacity: 0;
    animation: slideUp 0.9s ease-in-out 0.3s forwards;
    img {
      max-width: 100%;
      vertical-align: middle;
    }
  }
  .object-title {
    text-align: center;
    opacity: 0;
    position: relative;
    animation: slideUp 0.9s ease-in-out 0.3s forwards;
    img {
      height: 40px;
      margin-top: 10px;
    }
    p {
      font-size: 14px;
      padding: 0 16px;
      span {
      }
    }
  }
  .result-desc {
    background: #3f927b;
    border-radius: 0 0 40px 40px;
    box-shadow: 0 10px 0px 0px rgba(0, 0, 0, 0.1);
    margin-top: 80px;
    position: relative;
    opacity: 0;
    animation: slideUp 0.9s ease-in-out 0.3s forwards;
    div {
      text-align: left;
      padding: 10px 26px 20px;
      font-size: 14px;
      color: #fff;
      white-space: pre-line;
      word-break: keep-all;
      line-height: 24px;
      position: relative;
      top: -15px;
      &::v-deep {
        p {
          text-align: left;
          padding: 0 10px;
          margin-bottom: 5px;
        }
      }
    }
    &.e {
      background: #3f927b;
    }
    &.i {
      background: #c0a590;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      top: -99px;
      background: url(~@/assets/desc-top-e.png) no-repeat 0 center;
      background-size: cover;
      height: 100px;
      width: calc(100% + 10px);
    }
    &.i::before {
      background: url(~@/assets/desc-top-i.png) no-repeat 0 center;
      background-size: cover;
    }
  }
  .result-recommend {
    padding: 66px 20px 0px;
    li {
      margin-bottom: 40px;
      font-size: 13px;
      &:last-child {
        margin-bottom: 0px;
      }
      .box {
        background: #fff;
        position: relative;
        padding: 28px 16px 16px;
        box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
        border-radius: 10px;
        .box-title {
          position: absolute;
          left: 10px;
          top: -15px;
          height: 30px;
          line-height: 26px;
          background: #e05730;
          border-radius: 10px;
          color: #fff;
          padding: 0 16px;
          padding-right: 20px;
          text-align: left;
          img {
            vertical-align: middle;
            width: 15px;
            margin-right: 10px;
          }
          p {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            &::v-deep {
              b {
                font-size: 14px;
              }
            }
          }
        }
        .video-title {
          text-align: center;
          padding-bottom: 16px;
          padding-top: 8px;
        }
      }
    }
    .video {
      .box-title {
        background: #3f927b !important;
      }
      iframe {
        width: 100%;
        height: 200px;
        margin-bottom: 10px;
      }
    }
    .goods {
      .box-title {
        img {
          width: 17px !important;
        }
      }
      .goods-desc {
        padding: 10px 0;
        a {
          display: block;
          overflow: hidden;
          position: relative;
          .img-area {
            float: left;
            width: 50%;
            text-align: center;
          }
          .text-area {
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            text-align: center;
            height: 100%;
            p {
              color: #222;
              position: absolute;
              left: 0;
              width: 100%;
              text-align: center;
              top: 50%;
              transform: translate(0, -50%);
              span {
                display: block;
                font-weight: bold;
              }
              button {
                background: #d8d8d8;
                font-size: 14px;
                padding: 0 20px;
                height: 30px;
                line-height: 20px;
                font-weight: bold;
                margin-top: 20px;
                border: 0;
                border-radius: 10px;
                outline: 0;
                cursor: pointer;
                svg {
                  position: relative;
                  top: 2px;
                  right: -2px;
                }
              }
            }
          }
          img {
            max-width: 120px;
          }
        }
      }
    }
    .verse {
      .box {
        .box-title {
          img {
            width: 12px !important;
          }
        }
        .verse-desc {
          text-align: center;
          padding: 16px 0;
          word-break: keep-all;
        }
      }
    }
    .partner {
      .box-title {
        background: #274224 !important;
      }
      .box {
        & > p {
          padding-top: 12px;
          padding-bottom: 7px;
        }
      }
      .partner-desc {
        overflow: hidden;
        padding: 0 16px;
        .left-box {
          float: left;
          width: 50%;
          padding: 8px 16px;
          position: relative;
          height: 130px;
          line-height: 130px;
          img {
            vertical-align: middle;
            max-width: 100%;
            max-height: 110px;
          }
          &::after {
            content: "";
            display: block;
            position: absolute;
            right: -7px;
            top: 50%;
            transform: translate(0, -50%);
            background: url(~@/assets/i_love_s.png) no-repeat center;
            background-size: cover;
            width: 15px;
            height: 15px;
          }
        }
        .right-box {
          float: left;
          width: 50%;
          padding: 8px 16px;
          height: 130px;
          line-height: 130px;
          img {
            vertical-align: middle;
            max-width: 100%;
            max-height: 110px;
          }
        }
      }
      .partner-progress {
        width: 70%;
        height: 4px;
        background: #bababa;
        position: relative;
        /* overflow: hidden; */
        margin: 20px auto 10px;
        border-radius: 5px;
        span {
          border-radius: 5px;
          position: absolute;
          left: 0;
          top: 0;
          width: 50%;
          height: 100%;
          display: block;
          background: #e05730;
          b {
            display: block;
            position: absolute;
            right: -17px;
            top: -17px;
            width: 34px;
            height: 34px;
            background: url(~@/assets/i_love_l.png) no-repeat center;
            background-size: cover;
            font-size: 11px;
            line-height: 25px;
            text-align: center;
            color: #fff;
            animation: bounce 1s ease-in-out infinite;
          }
        }
      }
    }
  }
  .btn-area {
    padding: 100px 60px 120px;
    button {
      background: #eee7d9;
      border-radius: 10px;
      height: 45px;
      width: 100%;
      text-align: center;
      border: 1px solid #eee7d9;
      font-weight: bold;
      margin-bottom: 20px;
      line-height: 45px;
      font-size: 16px;
      box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
      position: relative;
      outline: 0;
      cursor: pointer;
      .up-guide {
        display: block;
        position: absolute;
        top: -65px;
        left: 50%;
        width: 180px;
        text-align: center;
        color: #555;
        font-size: 11px;
        background: rgba(255, 255, 255, 1);
        border-radius: 10px;
        padding: 7px 10px;
        padding-left: 12px;
        letter-spacing: -0.5px;
        transform: translate(-50%, 0);
        animation: effectTooltip 1.5s ease infinite;
        /* transform-origin: center right;
			opacity: 0;
			transform: scale(0); */
        line-height: 14px;
        box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1);
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          bottom: -10px;
          width: 0px;
          height: 0px;
          border-bottom: 10px solid none;
          border-top: 10px solid rgba(255, 255, 255, 1);
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
        }
      }
      .down-guide {
        display: block;
        position: absolute;
        bottom: -65px;
        left: 50%;
        width: 180px;
        text-align: center;
        color: #555;
        font-size: 11px;
        background: rgba(255, 255, 255, 1);
        border-radius: 10px;
        padding: 7px 10px;
        padding-left: 12px;
        letter-spacing: -0.5px;
        transform: translate(-50%, 0);
        animation: effectTooltiptoTop 1.5s ease infinite;
        /* transform-origin: center right;
			opacity: 0;
			transform: scale(0); */
        line-height: 14px;
        box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.1);
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          top: -10px;
          width: 0px;
          height: 0px;
          border-top: 10px solid none;
          border-bottom: 10px solid rgba(255, 255, 255, 1);
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
        }
      }
    }
  }
}
@keyframes slideUp {
  0% {
    top: 30px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes effectTooltip {
  0% {
    transform: translate(-50%, -5px);
  }
  10% {
    transform: translate(-50%, 0);
  }
  20% {
    transform: translate(-50%, -5px);
  }
  30% {
    transform: translate(-50%, 0px);
  }
  40% {
    transform: translate(-50%, -5px);
  }
  100% {
    transform: translate(-50%, -5px);
  }
}
@keyframes effectTooltiptoTop {
  0% {
    transform: translate(-50%, 5px);
  }
  10% {
    transform: translate(-50%, 0);
  }
  20% {
    transform: translate(-50%, 5px);
  }
  30% {
    transform: translate(-50%, 0px);
  }
  40% {
    transform: translate(-50%, 5px);
  }
  100% {
    transform: translate(-50%, 5px);
  }
}
.action-button {
  background: #d8d8d8;
  font-size: 14px;
  padding: 0 20px;
  height: 30px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 20px;
  border: 0;
  border-radius: 10px;
  outline: 0;
  cursor: pointer;
}
.submit-button {
  button {
    background: #d8d8d8;
    font-size: 14px;
    padding: 0 20px;
    height: 30px;
    line-height: 20px;
    font-weight: bold;
    margin-top: 20px;
    border: 0;
    border-radius: 10px;
    outline: 0;
    cursor: pointer;
  }
}
</style>
