import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: {
    id: "G-NJ36CJPX8L", // Google Analytics의 Tracking ID를 넣어준다
  },
});

/* eslint-disable */
Kakao.init("3cc0992248296fe49f1b20689bd34477");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
