const result = {
	entj: {
		isfp: 100,
		infp: 90,
		esfp: 90,
		estp: 90,
		istp: 80,
		intp: 80,
		enfp: 80,
		infj: 70,
		intj: 70,
		enfj: 70,
		istj: 60,
		entp: 60,
		estj: 60,
		entj: 50,
		esfj: 50,
		isfj: 50
	},
	entp: {
		isfj: 100,
		istj: 90,
		entp: 90,
		estj: 90,
		esfj: 80,
		infj: 80,
		intj: 80,
		infp: 70,
		enfj: 70,
		intp: 70,
		istp: 60,
		enfp: 60,
		estp: 60,
		entj: 50,
		esfp: 50,
		isfp: 50
	},
	intj: {
		esfp: 100,
		estp: 90,
		isfp: 90,
		infp: 90,
		infj: 80,
		enfp: 80,
		entp: 80,
		istp: 70,
		enfj: 70,
		intj: 70,
		istj: 60,
		entj: 60,
		intp: 60,
		estj: 50,
		isfj: 50,
		esfj: 50
	},
	intp: {
		esfj: 100,
		enfj: 90,
		isfj: 90,
		infj: 90,
		estj: 80,
		istj: 80,
		entj: 80,
		enfp: 70,
		entp: 70,
		intp: 70,
		intj: 60,
		istp: 60,
		infp: 60,
		estp: 50,
		isfp: 50,
		esfp: 50
	},
	estj: {
		infp: 100,
		isfp: 90,
		intp: 90,
		entp: 90,
		istp: 80,
		esfp: 80,
		enfp: 80,
		istj: 70,
		isfj: 70,
		estj: 70,
		esfj: 60,
		intj: 60,
		entj: 60,
		estp: 50,
		enfj: 50,
		infj: 50
	},
	esfj: {
		intp: 100,
		istp: 90,
		entp: 90,
		enfp: 90,
		infp: 80,
		istj: 80,
		esfj: 80,
		estp: 70,
		isfp: 70,
		enfj: 70,
		isfj: 60,
		infj: 60,
		estj: 60,
		esfp: 50,
		entj: 50,
		intj: 50
	},
	istj: {
		enfp: 100,
		entp: 90,
		isfp: 90,
		infp: 90,
		estp: 80,
		esfp: 80,
		intp: 80,
		estj: 70,
		esfj: 70,
		istj: 70,
		intj: 60,
		isfj: 60,
		istp: 60,
		entj: 50,
		infj: 50,
		enfj: 50
	},
	isfj: {
		entp: 100,
		enfp: 90,
		intp: 90,
		istp: 90,
		esfp: 80,
		estp: 80,
		estj: 80,
		infp: 70,
		esfj: 70,
		istj: 70,
		isfj: 60,
		enfj: 60,
		infj: 60,
		isfp: 50,
		intj: 50,
		entj: 50
	},
	enfj: {
		istp: 100,
		intp: 90,
		estp: 90,
		esfp: 90,
		enfj: 80,
		infp: 80,
		isfp: 80,
		entp: 70,
		intj: 70,
		esfj: 70,
		infj: 60,
		enfp: 60,
		entj: 60,
		isfj: 50,
		estj: 50,
		istj: 50
	},
	enfp: {
		istj: 100,
		isfj: 90,
		esfj: 90,
		estj: 90,
		infj: 80,
		intj: 80,
		entj: 80,
		isfp: 70,
		enfp: 70,
		intp: 70,
		infp: 60,
		enfj: 60,
		entp: 60,
		esfp: 50,
		estp: 50,
		istp: 50
	},
	infj: {
		estp: 100,
		esfp: 90,
		istp: 90,
		intp: 90,
		enfp: 80,
		entp: 80,
		intj: 80,
		entj: 70,
		infj: 70,
		isfp: 70,
		enfj: 60,
		esfj: 60,
		isfj: 60,
		infp: 50,
		istj: 50,
		estj: 50
	},
	infp: {
		estj: 100,
		entj: 90,
		intj: 90,
		istj: 90,
		enfj: 80,
		esfj: 80,
		entp: 80,
		infp: 70,
		isfj: 70,
		intp: 70,
		esfp: 60,
		enfp: 60,
		isfp: 60,
		infj: 50,
		istp: 50,
		estp: 50
	},
	estp: {
		infj: 100,
		intj: 90,
		enfj: 90,
		entj: 90,
		isfj: 80,
		istp: 80,
		istj: 80,
		esfj: 70,
		estp: 70,
		isfp: 70,
		esfp: 60,
		intp: 60,
		entp: 60,
		estj: 50,
		enfp: 50,
		infp: 50
	},
	esfp: {
		intj: 100,
		infj: 90,
		entj: 90,
		enfj: 90,
		estj: 80,
		istj: 80,
		isfj: 80,
		isfp: 70,
		istp: 70,
		infp: 70,
		esfp: 60,
		estp: 60,
		esfj: 60,
		enfp: 50,
		entp: 50,
		intp: 50
	},
	istp: {
		enfj: 100,
		esfj: 90,
		infj: 90,
		isfj: 90,
		entj: 80,
		estj: 80,
		esfp: 80,
		estp: 70,
		intj: 70,
		istp: 70,
		intp: 60,
		entp: 60,
		istj: 60,
		isfp: 50,
		infp: 50,
		enfp: 50
	},
	isfp: {
		entj: 100,
		estj: 90,
		intj: 90,
		istj: 90,
		enfj: 80,
		esfj: 80,
		infj: 80,
		esfp: 70,
		isfp: 70,
		estp: 70,
		enfp: 60,
		infp: 60,
		istp: 60,
		isfj: 50,
		intp: 50,
		entp: 50
	}
};

const getParnter = (type, partnerType) => {
	const percent = result[type][partnerType];
	let text = '';
	switch (percent) {
		case 100:
			text = '우리는 환상의 짝꿍! 주님이 주신 최고의 동역자!';
			break;
		case 90:
			text = '설마 제 마음 속에 들어와 계신 건 아니죠?';
			break;
		case 80:
			text = '우리 어쩜 이렇게 잘 맞아?';
			break;
		case 70:
			text = '함께할수록 기쁨이 넘치는 우리 사이!';
			break;
		case 60:
			text = '주 안에 우린 하나, 모습은 달라도♬';
			break;
		case 50:
			text = '주 안에 우린 하나, 모습은 달라도♬';
			break;
	}
	return {
		percent,
		text
	};
};

export default getParnter;
