<template>
  <div class="loading-page">
    <h2 style="text-align:center; margin-bottom: 130px;">
      <img src="@/assets/mbti-title.png" style="width: 160px" alt="" />
    </h2>
    <div class="loading-desc">
      <div class="loading-obj">
        <span></span><span></span><span></span><span></span><span></span>
      </div>
      <p>나와 어울리는 <br />성경 속 식물을 찾고 있습니다!</p>
      <div class="animation-img">
        <span class="left-eye">
          <b></b>
        </span>
        <span class="right-eye">
          <b></b>
        </span>
        <img src="@/assets/loading-obj.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Loading",
  mounted() {
    if (!this.getType) {
      this.$router.push("/");
      return false;
    }
    setTimeout(() => {
      this.$router.push("/result");
    }, 4500);
  },
  computed: {
    ...mapGetters({
      getType: "getType",
    }),
  },
};
</script>

<style lang="scss" scoped>
.loading-page {
  overflow: hidden;
  max-width: 450px;
  margin: 0 auto;
  background: url(~@/assets/loading-bg.jpg) no-repeat center;
  min-height: 100vh;
  padding-top: 30px;
  background-size: cover;
  .loading-obj {
    text-align: center;
    span {
      width: 12px;
      height: 12px;
      display: inline-block;
      vertical-align: middle;
      margin: 0 3px;
      background: #000;
      border-radius: 50%;
      position: relative;
      top: 0px;
      &:nth-child(1) {
        background: #e05730;
        animation: aniObj 1s ease-in-out 0s infinite;
      }
      &:nth-child(2) {
        background: #b67f39;
        animation: aniObj 1s ease-in-out 0.2s infinite;
      }
      &:nth-child(3) {
        background: #274224;
        animation: aniObj 1s ease-in-out 0.4s infinite;
      }
      &:nth-child(4) {
        background: #b67f39;
        animation: aniObj 1s ease-in-out 0.6s infinite;
      }
      &:nth-child(5) {
        background: #e05730;
        animation: aniObj 1s ease-in-out 0.8s infinite;
      }
    }
  }
  .loading-desc {
    p {
      margin-top: 40px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.5px;
    }
  }
  .animation-img {
    padding-top: 40px;
    text-align: center;
    position: relative;
    img {
      vertical-align: middle;
      width: 150px;
    }
    span {
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 25px;
      overflow: hidden;
      &.left-eye {
        right: 50%;
        margin-right: 15px;
      }
      &.right-eye {
        left: 50%;
        margin-left: 5px;
      }
      b {
        width: 20px;
        height: 20px;
        background: #284325;
        display: block;
        border-radius: 50%;
        position: absolute;
        right: 0%;
        top: 5px;
        transition: all 0.5s;
        animation: aniEyes 2s linear infinite alternate;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background: #f0e9d7;
        width: 100%;
        height: 0%;
        z-index: 5;
        animation: eyes 3s ease-in-out infinite alternate;
      }
    }
  }
}

@keyframes aniEyes {
  0% {
    right: 0px;
  }
  30% {
    right: 0px;
  }
  50% {
    right: 30%;
  }
  80% {
    right: 30%;
  }
  100% {
    right: 0%;
  }
}

@keyframes eyes {
  0% {
    height: 0%;
  }
  20% {
    height: 0%;
  }
  25% {
    height: 100%;
  }
  30% {
    height: 0%;
  }
  65% {
    height: 0%;
  }
  70% {
    height: 100%;
  }
  75% {
    height: 0%;
  }
  100% {
    height: 0%;
  }
}
@keyframes aniObj {
  0% {
    top: 0px;
  }
  40% {
    top: -12px;
  }
  80% {
    top: 0px;
  }
  100% {
    top: 0px;
  }
}
</style>
