import Vue from 'vue';
import Vuex from 'vuex';
import result from '../test/result';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		type: '',
		partner: ''
	},
	getters: {
		getType: (state) => state.type,
		getResult: (state) => result[state.type || 'istp'],
		getPartnerType: (state) => state.partner,
		getPartnerResult: (state) => result[state.partner || 'istp']
	},
	mutations: {
		setType(state, payload) {
			state.type = payload;
		},
		setPartner(state, payload) {
			state.partner = payload;
		}
	},
	actions: {},
	modules: {},
	plugins: [createPersistedState()]
});
