const result = {
  intj: {
    type: "INTJ",
    name: "조각목",
    kakao: "'조각목'과 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/2tJ7hjMwEjvEwK4_td3Xf9Pfbw8RpltaziLC5knit5GIrUhQviNuT1vqniX28BFIEPYU1_w-YvLhPRNY-7vNRsZtRaQ5xIS_mtoMnnrbaKKUBZafnHKJXL2lPuQW2cJBiTqkwmfhmxfbwiXF-zshdsXjj6pxCElaiRd-yC_-z3GM3RJagudablo6oQeescPAmIowa0QtTty0FJIh7Yu-_iu_6rCVTTLe07-HPAHHg0Tjhi89SnWDeqaiHGpuEoUZT5OsaHIVHsnY-cY1rEkpt3mARiV0Rki33WK8rJxdJzxMSBV7zehIBkzgIDhKARA5HLqAvaW5tnU5f_Kqh-Ut3DdbVea12r0w3ZOCvxk7PnUBDG3Y8qzKE5dPqxf6aZmz9w7XxgZNlwGlLi_Rgx4GhGrjKWo6WwdPMP9vUz4dpcgdjPGGzlN30lpFB59Bno01MNghAKfkhpwfk2LWqn9Ulni3AiDDt-KiwkvwNb5fnInBUmR0xcL4A06Qon5pN_Jr5Al6WlbTcA-NTcYlKVF4s5QcNOa8U0RpNvQQuG21Z9EcunTRPHXDqHiT-8xPZ2ZP7niBN02oIZHfiLzREwh9Zl2taW1MQls1NfWZt7rFzVVC8YbbGlsIkVu0lnb7wlhMXnMZPzDx65B4nP_CReyC8XJnRnOeiDxamSB4-sHO7Rb9AgiWLVMDMiWusVS5QA=s834-no?authuser=0",
    title: "나는 다 계획이 있어! 언약궤의 재료가 되는",
    description: `조각목은 언약궤, 분향단, 떡상, 촛대, 번제단 등의 재료로 사용될 정도로 쓰임새가 많지만, 가공을 위해 필요한 공정이 많기로 유명합니다.
    각 기구들을 만들기 위해선 여러 조각목을 이어붙여야했고, 그 절정은 성소와 지성소의 벽을 이루는 널판이었습니다. 총 48장의 이 널판 역시 조각목을 이어붙여서 만들어야만 했죠. 때문에 조각목을 이어붙일 때는 정확한 계획과, 설계가 필요했습니다.
    그렇게 조각목으로 형태를 갖춘 기구들은 모두 금으로 도금해 실제로 사용되었답니다.
    누구도 누더기같이 이어붙여진 조각목이 이렇게 반짝반짝 빛나는 멋진 기구가 될 거라고 생각하지 못했을 거예요. 매사 올곧은 태도로 계획 달성에 매진하는 당신은, 정말 조각목으로 만든 성막 기구처럼 빛이 나는군요!

    <p>- 모든 유형 중, 가장 냉철하고 객관적인 유형이에요!</p><p>- 외적인 명예보다, 능력적인 명예를 더욱 중시해요.</p><p>- 감정은 서툴지만, 감성은 오히려 풍부할지도 몰라요.</p><p>- 소수의 좋은 사람들만 친구로 두고, 다수의 지인을 피하는 편이에요.</p>
    `,
    video: {
      label: "<b>조각목</b> 와 어울리는 <b>영상</b>",
      title: "기독교인이 죽기 전에 봐야 할 영화 32가지 추천드립니다",
      link: "https://www.youtube.com/embed/gAJfeCLgcdA?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>조각목</b> 에게 꼭 맞는 <b>굿즈</b>",
      title: "힘내라 직장인 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=3314",
      image:
        "https://loaisloveoneanother.com/web/product/big/202408/be0ca2cc09cceeff1f307636175efda2.jpg",
    },
    verse: {
      label: "<b>조각목</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "너는 기도 할 때에 네 골방에 들어가<br /> 문을 닫고 은밀한 중에 계신 네 아버지께 기도하라<br />은밀한 중에 보시는 네 아버지께서 갚으시리라<br /><br />마6:6",
    },
    partner: {
      label: "<b>조각목 (INTJ)</b> 와 나의 조화는?",
    },
  },
  intp: {
    type: "INTP",
    name: "흑단나무",
    kakao: "'흑단나무'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/54Esk9dsOniQfYb9PFtH7NGmcc_5wvaXN-Mv_00Gl1wq1xniriiXLEW8e1k9yT6kbQ8XY-K9p5kUUIZER7Bg3ZnAofGl2HdP1aImXPuIFppFMNi0dpeh6yO0mWo5utrZEiTgJEWKz5at5btQ0gE70alQF_34YoKH4gZY-xpOHBQjGbrYkxp8uifeo75HFAwYsiU23b7fwMwWipE30OkOgtXEhJwtgJABXIrWKbH0-eaJ0IbERx4hClqdOe8PObCZOcOpQPQEjwX-FYQqTPhupWP5QiZikbtnHhYNaX3u1lPNr4K6r6jLRvM80QhvVZiwuAIdr40wB_cNtO9qp8oI4yOgcoMt396J8E5B99SCTYWQNaRZyn4LdlKsLraK-yIDdRtHVtqbSxu1hJOuwmaaY-mP1upN2Zyw2Wm6EXDVU7l2NKQU-vGP8BQu2nDgW2PG-lQiy-xBaW8BAbjQ8xSfM21oRWscgcTXVyOrlujymA_Rpoa3AHLgyBR1RDwnCuQx9-F6a41YbdqKHLsYkIQK_5zMLwLh9-3fmyRcv3EZM2k5yeADaV63vl3FeZgqDhAbveu95VGtDmSHsaUv228R8bjP6UG0TmYeLU9BQcs4Ttz8Yq2dlx36NZZaBs6aNNVPl7jR-B5m3vk_9AHB7X44pZ1wfri5WVzc8UBx-TjoHA6q-xsT9SWb-hg7gt3RsA=s834-no?authuser=0",
    title: "평범함을 거부하는 색! 늘 새로운 방법을 찾는",
    description: `흑단나무의 안쪽을 보셨나요? 일반 목재처럼 생각하면 완전 금물!
    칠흑처럼 검은 나무 속은 어찌보면 시크하게도 보일 정도입니다.
    호기심 많고, 평범함을 거부하는 당신과 딱 어울리는 나무죠.
    성경에서의 흑단나무는 단단하다는 뜻의 '호벤'이라고 불렸는데, 단단하고 변질이 없어 상아와 함께 그 값어치가 상당했다고 전해진답니다.

    <p>- 지적인 면이 매우 뚜렷하게 드러나는 성격!</p><p>- 마음에 없는 소리를 잘 못하고, 매우 솔직한 성격이에요.</p><p>- 관심있는 분야에서만큼은 전문가!</p><p>- 자율성, 자유, 솔직, 독립, 기발한, 복잡한 등의 단어와 어울려요.</p>
    `,
    video: {
      label: "<b>흑단나무</b> 와 어울리는 <b>영상</b>",
      title: "Peace on Earth｜The Bonfire in Back Garden",
      link: "https://www.youtube.com/embed/_LdrpoPL5OE?si=hfoDJmFMsswJM4kv",
    },
    goods: {
      label: "<b>흑단나무</b> 에게 꼭 맞는 <b>굿즈</b>",
      title: "리멤버 갓이즈 굿",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=3271",
      image:
        "https://loaisloveoneanother.com/web/product/big/202407/9f7c091279ec153104324b74fff2121a.jpg",
    },
    verse: {
      label: "<b>흑단나무</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "여호와를 경외하는 것이 지혜의 근본이거늘<br /> 미련한 자는 지혜와 훈계를 명시하느니라. <br /><br /> 잠언 1:7",
    },
    partner: {
      label: "<b>흑단나무 (INTP)</b> 와 나의 조화는?",
    },
  },
  entj: {
    type: "ENTJ",
    name: "아몬드(살구)",
    kakao: "'아몬드(살구)'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/BVpQMzMQKraN0Akn70YAwAxxI6bVLoiI1VuRbWx08PWTL09xvDqdhLNU-28vgYW1iE0GaoIYzimh8uS_zSDh_BjB25f5UDvzf9pD5OSVoPVfiVFS7zDJIBp2t9vrkc63s1xAx3diCnAWbz02ub5XLgTGlC0iwnyIaw9FG8Q4W7_2VtUOXi9MRb_UM5j_67v8kwwlfj9I43lt1pC4l8GOpaSoJ_pckavxeUzkC25VSF64-lGIJNvaebtSNi1OAeqkEhiz-6Fjwl4K4oUB8MWDEgngeqbf7Rz3jKHaUTQllvxIAVcWQNbk6u-FZTyRfeV4XbWtPSNgOYn27s_039wxzwFz4R9KCDxuMRzQGiK9dI8dHJ5kRzP5cDZYqN4Sll4phemebYYkeaMzpqSz-WycL30kL02dTx5cvOgigD05oMgko0BQqxxR87G9kAwGfrFGk1-Bt0JCNcxoSU5bgkariU28PDUKLxmdL6nrklH0G2dKM5jYpHZpsKDXUqMuyc5PQgmJOVMf9Si-rnM1Z_v274S0pSUZGcPeWkrjzqr57J_v-T77eSv9xEWYKIFDHiCjcqKu8nqt5XM325yD1Nla0saD7X-ErffbQ_s-Pm82Pde9WWKRTO5HCALxKuBlyDDw7AX1ZK1Izgs9YgurTfR7gFQpRkLRK-aJUASX1wh_n7Urj-P9JM_H8fmSlZcr8Q=s834-no?authuser=0",
    title: "봄이 왔어요! 흔들어 깨우는 통솔자",
    description: `예레미야가 선지자로 부름받으면서 최초로 본 환상이 바로 '아몬드(살구나무)'의 환상이었죠.
    아몬드는 히브리어로 '깨우다, 지키다'라는 뜻을 가지고 있어요.
    이른 봄 맨 먼저 꽃이 피는 아몬드의 꽃 같은 당신!
    리더십이 강한 당신을 따라 모두가 봄이 왔음을 알게 될지 모르죠.

    <p>- 당신은 타고난 리더형의 인재!</p><p>- 상상력이 좋은 편이고, 지적 욕구가 강해요.</p><p>- 가까운 사람에게 헌신적이지만, 멀어지면 뒤돌아보지 않아요.</p><p>- 권위적이지 않고 아랫사람과 의논을 잘해요. 하지만 살짝 완벽주의적인 성격도 있어요.</p>
    `,
    video: {
      label: "<b>아몬드(살구)</b> 와 어울리는 <b>영상</b>",

      title: "COUCH WORSHIP [Official Video] <br />COMFY(돌아온탕자)",
      link: "https://www.youtube.com/embed/mo2Nb5WUKXg?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>아몬드(살구)</b> 에게 꼭 맞는 <b>굿즈</b>",

      title: "말씀이 가득한 삶",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2891",
      image:
        "https://loaisloveoneanother.com/web/product/big/202401/c7af276ad7c454c49a4bce7d813aff5c.jpg",
    },
    verse: {
      label: "<b>아몬드(살구)</b> 가 묵상해볼만한 <b>말씀</b>",

      title:
        "만일 여호와를 섬기는 것이 너희에게 좋지 않게 보이거든<br />너희 조상들이 강 저쪽에서 섬기던 신들이든지<br />또는 너희가 거주하는 땅에 있는 아모리 족속의 신들이든지<br />너희가 섬길 자를 오늘 택하라 오직 나와 내 집은 여호와를 섬기겠노라 하니<br /><br/> 여호수아 24:15",
    },
    partner: {
      label: "<b>아몬드 (ENTJ)</b> 와 나의 조화는?",
    },
  },
  entp: {
    type: "ENTP",
    name: "호두나무",
    kakao: "'호두나무'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/dryZ1jJVtlkWNMkA0NUYYAxlXSNnZQ0ztAiP0UGBhXVK0jYeILpFZs8xk8TTmyDE4GqbXxwmix10GukhAi5M0z8jWZ8-nKaU3R9OLeQYlQb3TMzCUfn-mxi9iw262SqG10oRti8uPENazrBxdWTKyq7j4OEw7unPrwgTZZi6qbnR63m2p7MkyExKrO0VAUDXtpOARrholTpEtwS4czVgz7Z5oleL9yxPjFzuky-bM9PsoFav6CxqB81klFfeYyIcfWbYFn3ugSqU7cTOwXSNbakB_aLaBJ1FIcJQtWBDjKDethXocKbj0G7Y4pb7DRU_5VhCYcx8h1E-HWrMlTwGDToyTNaLphJajqtCP08_guLU4n7EkR4iGMjqUO_-BeYfYzaiz2G169HWTXYh326A7urcGtJ0zoWX4hBlUgO-UWQavLFOGWnUP2isnWScmV1keYcA_CkJerbXKV2M4cuCfdnGTRkKJv1m0EW7oLDmaaQFJbUF6xCQ4FTtxAcf0Tsl9CkmRPVHFQyErSsE57gUySFfCX0gzOzv6Hc83lDUClDj3KkWJfbq8WfjsH8A38aTcSFg8sl1Hrrwut1zV0acfWb4VBJoRGSplPA1sFH3FkybsfMf15xlRSriZ6yRCGsFdWPaJS1flo4UU0_1BZqtmGdVXXFNwWRFDIkQL1ih8_qaNY2xImpPCIpyUOoUjQ=s834-no?authuser=0",

    title: "단단한 껍질 안에 화합을 이룬 열매",
    description: `솔로몬이 술람미 여인을 만나러 갈 때 등장하는 것이 바로 이 호두나무 동산이었답니다. 호두는 단단한 껍질과 그 안에 내용물이 단단하게 얽혀있기 때문에, 결혼이나 연합의 상징으로도 사용되고 있죠.
    당신은 호두의 겉면처럼 겉으로 감정표현을 하는 일에 서투를지는 모르지만, 분명 공동체를 연합하게 하고 이끌어가는 것에 대해서는 멋진 리더가 될 자질이 있답니다. 당신이 일구어낼 크고 작은 성취와 도전들이 매우 기대되는군요!

    <p>- 대개 다재다능한 팔방미인인 경우가 많아요.</p><p>- 통찰력이 뛰어나 다른 사람들이 놓칠 법한 부분도 쉽게 눈치채요.</p><p>- 주관이 뚜렷하며, 경쟁심이 강해요.</p><p>- 특성상 벼락치기를 하는 경우가 많아요. 그런데 의외로 잘 풀린답니다(?)</p>
    `,
    video: {
      label: "<b>호두나무</b> 와 어울리는 <b>영상</b>",

      title: "어린이찬양메들리",
      link: "https://www.youtube.com/embed/6Xdy8I6aGpg?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>호두나무</b> 에게 꼭 맞는 <b>굿즈</b>",

      title: "아이엠 크리스천 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2050",
      image:
        "https://loaisloveoneanother.com/web/product/big/202303/273ba6081f98542675fac5fb699b2edf.jpg",
    },
    verse: {
      label: "<b>호두나무</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "여호와께서 말씀하시되<br />오라 우리가 서로 변론하자 너희의 죄가 주홍 같을지라도<br />눈과 같이 희어질 것이요 진홍 같이 붉을지라도 양털 같이 희게 되리라.<br/><br /> 이사야 1:18",
    },
    partner: {
      label: "<b>호두나무 (ENTP)</b> 와 나의 조화는?",
    },
  },
  infj: {
    type: "INFJ",
    name: "로뎀나무",
    kakao: "'로뎀나무'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/pw/ACtC-3etJQPhlbk-KbNvo5WBWFYtNZa9S-z27bqqHlkhz52L9NpXsMAGwr1QhtWadKyTJx_N7MSmDkRXjHpOLT3qIPs6WBuvEamZxD3gp6xqQSotZ3i4h9v56CzyamfnOnvU5lRBNdFGtQEF-mQrDqRfz7G-v6KEl8PhULUxpsdFkPYbNRQNVNj7H9pfV-MlYWTtqk0SHjGqceF8V1xZAcEGelztde1OuyFup06vencQ9eVSs7OfygOb2kOm3tj2McKiydB9Iq8-VelawQe6xR_iaTpeqIJtxoq4VHh0XjCJ0NG_c1yuG8UFOGQ8QrtVaIIiokVdfOJdb8hok2seyJVoC7690IApQC34_02T6PNN9DPz91i1gVU1EUwa7mKeVBpCU6C4BJ3xa0zElCuGuLFgF64NELkIYZdcAclrUaTdajgvvqJm2VsFHPJd-95zIYVlhGowyLNCl5g0FXcT9t_gy1gjV81OkRHggsLUbosm6oN_Vrl0cZzDyA3LmMD-vyiFtSjyIPY-uiswfCDywYyUPV9xF8ATLI1-ozixRkBCfoKVSBTAyVxa7ePGlD31SKOofcewvW88BVlTL_DD5kK6kRNkc9ayJxwGkBDhFPX9ypP-BRvVtxQ6reWLPYSruSTpbxKLsI2cCmbyuVHqKy-SaNEdEJU_QFE6QTNo3H1boSYxkd2CGKUhLHILSi3QRGl5ADuQfDt6MiuVkPZxOKuf=w231-h232-no?authuser=0",

    title: "지친 이에게는 그늘을, 불의한 이에겐 징계를!",
    description: `지친 이에게는 그늘을, 불의한 이에겐 징계를!
    엘리야가 지쳐 쓰러진 자리를 기억하실 겁니다. 그곳이 바로 로뎀나무 아래였죠. 하나님께서 천사를 보내 떡과 물로 원기를 회복시키셨고, 로뎀나무 아래는 회복의 상징이 되었습니다.(왕상 19:5~7)
    또한 로뎀나무 숯불은 화력이 강하고 오래 타기로 유명합니다. 이런 배경으로 이사야에서는 불의한 자들에게 로뎀나무 빗자루(멸망의 빗자루)로 그들을 청소하겠다 라는 하나님의 진노하심의 말씀을 만나볼 수 있습니다.

    <p>- 가장 소수의 유형, 영감이 뛰어나고 깊은 통찰력이 있어요.</p><p>- 미래를 예측하는 걸 좋아하고, 상상력이나 독창성이 뛰어나요.</p><p>- 희생정신이 강하고, 사회적 불의에 민감해요.</p><p>- 대체로 사람들을 돕거나 약자의 편에 서는 것을 좋아해요.</p>
    `,
    video: {
      label: "<b>로뎀나무</b> 와 어울리는 <b>영상</b>",

      title: "공황장애와 기독교 유튜버",
      link: "https://www.youtube.com/embed/QEnsWIJdt6o?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>로뎀나무</b> 에게 꼭 맞는 <b>굿즈</b>",

      title: "채우시는 삶",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2806",
      image:
        "https://loaisloveoneanother.com/web/product/big/202312/1cfcf02dfe20db76fa966a5189897dd1.jpg",
    },
    verse: {
      label: "<b>로뎀나무</b> 가 묵상해볼만한 <b>말씀</b>",

      title:
        "우리가 선을 행하되 낙심하지 말지니<br />포기하지 아니하면 때가 이르매 거두리라.<br/><br /> 갈라디아서 6:9",
    },
    partner: {
      label: "<b>로뎀나무 (INFJ)</b> 와 나의 조화는?",
    },
  },
  infp: {
    type: "INFP",
    name: "떨기나무(가시나무)",
    kakao: "'떨기나무(가시나무)'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/qVFtipp5O7qFOPoOTZTztvnbfJDVyVRPA626ud5xx5jwVVO7X1qXxv2aYjRr8Y_5VgdacAgqvqL3ttprNt4VruLmw9qp-3JPE0g0Uwujw8VuCgVxlEd7eNknOzPwW65Z9INd4kwI9wjvoB4vTdKc4Q1OjzHDYpqS4c0S6IGjaxcP8Pg-J6Eizp_CjaD2uvYWcrSgdwaaLZlwmWiunVBHDM5vJUTHckROYmRTsp336KW_3Q4mrz14EFAxtTMtqrj9DARyFcUE05z44Sjst53qfN5NzVInPpOm6iuzaWoNCcxLmC1YBcxT_-uMggfH0GV1ZLwrhm7OVmDsQHIB-fubof5-KXHa5hmfya0Vg3P2iaMPv-agTDmfq0o-Ix8EQbqNgku9729HPBreCZZA8FCt5mqH5qnZYhmHZbLwtLjZH6eNtBgeuDcU7NxMsV56vyfq4NzCUrcrmuTUKFQN8uy9KbVLQXKNiLvucrjCIWHfaFcxi14IfE_1Z45XC0ki5waGy0SnOGPzVUaMdnDNfdEUqLdduQjw9aw3UQySqL4zocyRrx4PSDZnhQAzEJHgwLb1WzRh4z3YQd0Vr47qx8HemSzdJnM8-30F31n9_5_5E-izaFR_fqFmZUM4HEs6kEC0K2Ywr3ny9cpduSTCcDE-UOs07xaL8nDWx9aN4fiwBK7c9ijQ78Cayj70cTIhEg=s834-no?authuser=0",

    title: "당신은 불타오르는 중재자!",
    description: `떨기나무는 굉장히 영적인 식물입니다.
    하나님께서는 떨기나무 불꽃 가운데서 모세에게 나타나셨습니다(출 3:2~4).
    사막에서 자생하는 떨기나무는 사막 열기에 타거나 시들다가, 비를 맞으면 겨우 기운을 얻어 살아나기도 하는 기복을 가지고 있지만, 떨기나무가 불탐(희생)으로 인해 모세는 하나님을 만날 수 있었습니다.
    모세는 생애 마지막 축복기도문에도 이 떨기나무를 언급하며, 하나님이 주신 소명과, 하나님께서 이스라엘과 함께한다는 의미를 암시하였습니다(신 33:16)

    <p>- 세심하고 디테일하며, 이해력과 포용성이 뛰어나요.</p><p>- 가식적인 것을 싫어하는 성격, 대체로 남도 그래주길 바라요.</p><p>- 언제라도 기회가 오면 활활 타오를 수 있는 열정이 숨어 있어요.</p><p>- 비판에 약하고, 감정기복이 있는 경우가 있어요.</p>
    `,
    video: {
      label: "<b>떨기나무</b> 와 어울리는 <b>영상</b>",

      title: "목회 25년차 아버지에게 설교해보았습니다.OSS",
      link: "https://www.youtube.com/embed/qo9TIf2Kdx0?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>떨기나무</b> 에게 꼭 맞는 <b>굿즈</b>",

      title: "감성있는 자취방 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2240",
      image:
        "https://loaisloveoneanother.com/web/product/big/202304/015054458f71416f2aa1c563b7e04cc4.jpg",
    },
    verse: {
      label: "<b>떨기나무</b> 가 묵상해볼만한 <b>말씀</b>",

      title:
        "이와 같이 우리 많은 사람이<br />그리스도 안에서 한 몸이 되어 서로 지체가 되었느니라<br/><br />로마서 12:5",
    },
    partner: {
      label: "<b>떨기나무 (INFP)</b> 와 나의 조화는?",
    },
  },
  enfj: {
    type: "ENFJ",
    name: "우슬초",
    kakao: "'우슬초'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/pw/ACtC-3emh9G-yb5l7vZy2bMqbSF-dUI2yCTT6QPAFHMoEQIOo6kTk0o9v5r-icNml6IzVcGTagxSrci4ftu8RK6IXI4M2k0u90-18gd6tBw88NTUh-tW9K87oyEqC1isWYMuylYJaZ4POKk15yUUx9dhKoJ16Qig3yO12C0xsG6LjolHB5jmHKwDMhXdnfAx5JsFCDf37TNWl5V0_PibeYObkRgd1DKggT0q7kfF-OjnW8AfXHwMzzsFXJWecwZ89jjUFpk92RCxy5kgnO9s6PFKANhDc2t52Mnlh66GMgSZLzeeyuBlttpy2dDir3isTocb2HZr09hTSa8pwWJiZSXwHuzcq9l76Ge6zuS7V5wthy0-yulCu09OzyWJ9BDfHNtQp6NcXBLGlPLhJAxF0iSaVEWOi9x4r-j5RacwDLrZjwait-A3cqzB21E_L0iiYDqgmRFXufgKfnGqESJv6SMj33uK-fzdfb-BrHynq5axL9C2HuSxEhqOrIp-ag2Xini7NexXmLrhIQui_UnAHu2Mqam1hW-jg2nmwLIkcuisldwpSy9eIhV61GVxS8-jF2zE9NPbPgr1VGjueyfBWokvQv99L7zOwO8YG4I_x8_EaNUe8xJpu_m500rrw_Wb_skKs38SfAVZ40Z8YjAuo8Hu5qkvhzRbm2qVoVQFm1d6YOV7WSaFKgIn-32S6J4Eh2nJ7GQDF1i_x1weylYB_3lp=s232-no?authuser=0",

    title: "부드럽지만 강한 생명력! ",
    description: `우슬초는 시원한 향이 있고, 소독 진정 효과가 있는 중동지역의 풀로, 사람이나 제물을 정결하게 하는 데 주로 사용되었습니다. (민 19:18)
    작고 부드러운 풀에 불과하지만 강인한 생명력을 가진 우슬초를 닮은 당신은 늘 타인에게 마음을 잘 여는 편이며(그래서 힘들 때도 있지만), 그들의 문제와 고민에 관심이 많습니다.
    타인을 위로하고, 회복시키며, 다시 정결케하는 자리로 인도해줄 수 있는 부드러운 당신은 역시 우슬초를 닮으셨네요.

    <p>- 우리나라에서 제일 적은 유형이래요!</p><p>- 사람을 좋아하고, 남에게 잘 맞춰주는 성격!</p><p>- 정, 눈물, 동정심이 많아요. 정이 너무 많아서 가끔은 문제가 될때도.</p><p>- 타인에게는 관대하지만 본인에게는 엄격해요.</p>
    `,
    video: {
      label: "<b>우슬초</b> 와 어울리는 <b>영상</b>",

      title: "제이어스 - Breakthrough (부제 : 나는 달려가네)",
      link: "https://www.youtube.com/embed/BEXi88t6Nao?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>우슬초</b> 에게 꼭 맞는 <b>굿즈</b>",

      title: "온전한 사랑 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=3105",
      image:
        "https://loaisloveoneanother.com/web/product/big/202405/a13d7c8666227bc7c163d05e448be1a5.jpg",
    },
    verse: {
      label: "<b>우슬초</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "내가 네게 명한 것이 아니냐<br />마음을 강하게 하고 담대히 하라 두려워 말며 놀라지 말라<br />네가 어디로 가든지 네 하나님 여호와가 너와 함께 하느니라 하시니라. <br /><br /> 여호수아 1:9",
    },
    partner: {
      label: "<b>우슬초 (ENFJ)</b> 와 나의 조화는?",
    },
  },
  enfp: {
    type: "ENFP",
    name: "상수리나무",
    kakao: "'상수리나무'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/GiTJFpVT9H0sg1kDh8NYzVOnIgrkQU38_3y2rxz0TVbyl8L5afgcEnI6jDiomMQ9zfITVR8QPW6YPwNlSQ2MccxLGP54ZMOzdT50tG3VPZdJIEmN5Rl_Sc3PlESQK2DIqgQPMumkPZKfUFmhpFRGmME8NwWgnT8mHiypp67UmFY_KRb8qumI9A1JSIEteCn7uMzVXtWIGY5q9CRyM86TJ9FfJdUfFPKIhbglft-y6EllukQgWIWCGEJ6YOGDJoxXzRxNhhRKiRkQs3PsNWVwLVnVAQRGtuv_d-DNDUWZdnWM7r-HpzB5hTVenhBULMV9lqAac7lrP3ngtNMGRmzleL3oaupoTy4GSBo-2Q0RbV46ScoXqOYD-VzQZ8Ffk4b-dj5XppZXhMVJ28rvkkqeEntfiRcNDAEHj-PCyDvf7Se-dhMrz91P0vUg6l0LqTySQfKtyqCtmLNcUsQzB2TMtfyRXaol74lEJRUPhtWSZfLwL29KiorZYX7Pb_L-5nHcJuOD28oDqDaYA342omOIflsXgdp8XEhNJdgwdM2WH4EjyMlnpZ5uHrGC1sAO8nh045WRpdp6CNdYMXGfLpuRLn83VoaeX0xwZ6Fpx6oGXTDOsXu4UgIXVEw_MToEctUPg-GJhWjmBLIhTinHtcUZLKolvtkoCSvr_FVjoXFCOoqO7G6oo5t5T9ieezaeFg=s834-no?authuser=0",

    title: "늘 새로운 일들이 일어나는 당대의 핫플레이스! ",
    description: `성경 속 상수리나무는 백향목과 견줄만큼 크고 값어치 있는 나무였습니다.
    나무 그늘 아래서 회집이나 다양한 모임이 가능할만큼 컸기 때문에 우상숭배 등의 장소로 이용 되기도 하였지만(사 1:29), 그만큼 수 많은 사람들에게 도움을 주었던 당대의 핫플레이스였답니다.
    또 재생과 부활의 상징으로도 언급되는데, 뿌리가 죽지 않는 이상 다시 살아나는 재생능력이 탁월한 상수리나무는 '밤나무와 상수리나무가 베임을 당하여도 그 그루터기는 남아있는 것 같이 거룩한 씨가 이 땅의 그루터기니라(사 6:13)'의 말씀과 같이, 이스라엘이 다시 소생하게 될 것을 예언하는 상징으로 언급되기도 하였습니다.

    <p>- 감정이 얼굴에 잘 드러나요.</p><p>- 사람을 기쁘게 해주는 타고난 능력이 있어요!</p><p>- 사람들을 만나는 것을 좋아하지만, 가끔은 혼자만의 시간도 필요해 해요.</p><p>- 가끔 고집스러운 면도 있지만, 융통성도 높아 심하게 고집부리진 않아요.</p>
    `,
    video: {
      label: "<b>상수리나무</b> 와 어울리는 <b>영상</b>",
      title: "아가파오워십 - 더 블레싱",
      link: "https://www.youtube.com/embed/D-WJ0-6ThQE?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>상수리나무</b> 에게 꼭 맞는 <b>굿즈</b>",
      title: "해피 스마일 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=3269",
      image:
        "https://loaisloveoneanother.com/web/product/big/202407/197a35a2c86e664892630e714c64c88e.jpg",
    },
    verse: {
      label: "<b>상수리나무</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "모든 지킬 만한 것 중에 더욱 네 마음을 지키라<br />생명의 근원이 이에서 남이니라 <br/><br />잠언 4:23",
    },
    partner: {
      label: "<b>상수리나무 (ENFP)</b> 와 나의 조화는?",
    },
  },
  istj: {
    type: "ISTJ",
    name: "종려나무",
    kakao: "'종려나무'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/pw/ACtC-3cYhbpLire3EoY8V0vB19Qqv7yD3v2YLqjNTh2pmHiqtorzdo8bTk8UlyvY1dd7AiwkQJkLg0hZ6IUor9xVLL_QoX9P0TX2PWi6rHEFR9lkneQOx-01GK6wyCRae1NorSz8Kqum4rYBegg-rotZ1-Vf_mVaRDpV-FUPKaGuumN_2ZZF3QCyOjDv9odbC03sRmjSRUldRFZUI_UzI9GOGQMWDKoKKBdwSjxKKWwUbjJaNyGpyrxdnd26hR8oEWdWZdqUpiCvtao1LeL3vI3-f3BRr1VCmbuElHrEHfj7opesGFi74FtmPLOAfj0fxgUGgppGdXbXcVgMVslC07K_ZTwHAvEtPilPyKKMjW1xoMqTr8xBfi-LXbHwJlU372Tq5rhKZfyhGCNSBQb-HW2UMYEK684H--NjDaDm5i8UkNpQqveDCTBYjlZrzA1j6Zcezi1JFTC34zvXGfYcvnpavgiWa-nBHnWbI9C0U3EoKI6frw9Ap39xhVwF7Mrm3jBEKuLl9XePBqsW68JNSW6xpRnLf7pQ87e0rCreezsd9-qVXlfvgG4bWkowksRA7tM2KH3W94WrFQJ5q8JuBBVoCiOAuTCv2Af7G546Vt4ILspO6Vfvttm4sRdNXAc5ygeOEKel-n7mfWiMJtSlrB-RvOR9MM15E_XUVvqNPyRuImmWXXZWQvouz9SwEkO3jr7ArCZHX0zPVNWZfjPNdIzy=s232-no?authuser=0",

    title: "정직한 사람이 거주하는 평화의 상징!",
    description: `예수님이 예루살렘으로 입성할때 백성들이 환영하며 흔들었다는 종려나무!
    종려나무는 번영과 평화, 정직, 거룩, 부활 등을 의미합니다.
    사사기에서 드보라가 종려나무 아래 거주했다는 것은 그가 정직한 사람임을 상징하는 대목이죠(삿 4:5).  책임감이 강하고 헌신적인 성격의 당신과 꼭 어울리는 식물이라고 생각해요!

    <p>- 주어진 업무나 책임감을 정직하고 성실하게 완수해요.</p><p>- 약속시간을 철저히 잘 지켜요!</p><p>- 문제를 해결하는데 과거의 경험을 잘 적용해요.</p><p>- 인내력이 강하고, 꽤 의젓한 성격으로 보일 때도 많아요.</p>
    `,
    video: {
      label: "<b>종려나무</b> 와 어울리는 <b>영상</b>",
      title: "Gina - 주 사랑이 나를 숨쉬게 해",
      link: "https://www.youtube.com/embed/QpvgvnmixAU?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>종려나무</b> 에게 꼭 맞는 <b>굿즈</b>",
      title: "MBTI 'J' 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2811",
      image:
        "https://loaisloveoneanother.com/web/product/big/202401/eac8c29aba2ec026bfd3bb519eb5141f.jpg",
    },
    verse: {
      label: "<b>종려나무</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "주는 미쁘사 너희를 굳게 하시고<br />악한 자에게서 지키시리라<br/><br />데살로니가후서 3:3",
    },
    partner: {
      label: "<b>종려나무 (ISTJ)</b> 와 나의 조화는?",
    },
  },
  isfj: {
    type: "ISFJ",
    name: "올리브나무(감람나무)",
    kakao: "'올리브나무(감람나무)'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/9ZQ8SRt76SQ64pkwfHkIQ2TuqukDUPsh733N3exTww7xjfrUwyt6V_nGKlWnjmbIlLd6QIYsPUJHopx0j286MnSfck9zPGhRS6FJ4NNexMkLjhwbL4mYJZx6eYRx-_FFVHKTFXN78TbTEDp_lWmvt4UCwFYnmkiC1amVo1vQBcPstlg81IXsriVz01HVqQjzlSVP8nBhKIWdRPVhBQe8fiAr3xXVXnJ8_86N6JXtnBpcA6KxMssoszQhNjeVTAISyd2fGCqA_A8C5Xd9LLxMtZy-Uy_ZL58UoIecCy70Pm5Nkr_JuyZ2_F-zgGaNswXEbAvkywmnETxdHeyp7AHvu01VQQMJ1Dl_qBVIXWxzvoRlGc68sQBG_4G8rjeHsmv_N9wi9M06pdZuN5mIrFDQNXp7njLUhp3xbnSm2aHQyuJRvuuGaPAhBUMoVPiGA60zBKjaQ01NmaEPfsKgYw-1Ncl0CQ3TMBmRN8TTshqOq-WNYjV5dSkEOAO3ae1wF2bN7_-oj2iOTWSosp99S1bXdVqMmWXDBlu9UTP2fJjOYaSElcZFWDTjjFz3RxHy24QqViO5nb82wLWyn2E74tF0yiVygPGHSnHB0xfhiAFQXCUdfRwsFOb4OjGKulW3Tv6hFzMRenBpQiPO4tJLsZd_3qyLGy5BUbqECDiBpqd5ykgqr_vOkg-vkunmwucCxA=s834-no?authuser=0",

    title: "버릴 곳 없는 고귀한 나무",
    description: `올리브나무는 버릴 부분이 없습니다.
    솔로몬 성전의 문짝, 문설주, 그룹등을 만드는데 사용했으며, 가지는 목자의 지팡이를 만드는데도 사용했죠. 다윗이 골리앗과 싸울 때 가지고 나갔던 막대기도 올리브나무의 가지였답니다.
    이 올리브나무는 매우 인내심이 강한 식물입니다. 열매를 맺기 위해 30년이 넘는 시간이 필요하거든요. 참고 견디는 인내의  시간 다음에 오는 올리브나무의 열매로 만든 기름은 기쁨과 영광의 상징이었습니다. 왕이나 대제사장에게나 붓는 고귀한 물건이었죠. 마치 당신을 보는 것 같아요.

    <p>- 조용하고 차분하지만, 친근하고 따뜻해요.</p><p>- 눈치가 빨라요! 상대의 성향과 분위기를 잘 파악해줘요.</p><p>- 남에게 싫은 소리를 잘 못해요. 반대로 싫은 소릴 들었을땐 표현은 못하지만 좀 서운해요.</p><p>- 본인이 어디에든 도움되는 것을 좋아해요.</p>
    `,
    video: {
      label: "<b>올리브나무</b> 와 어울리는 <b>영상</b>",

      title: "콜드워터워십 - 워십 피아노 BGM",
      link: "https://www.youtube.com/embed/QCeNmv8ivpk?si=Njs_OiyjyigYSA-H",
    },
    goods: {
      label: "<b>올리브나무</b> 에게 꼭 맞는 <b>굿즈</b>",
      title: "십자가를 따라",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=3272",
      image:
        "https://loaisloveoneanother.com/web/product/big/202407/93e7b12a80fc4e6146489a042d2e16a7.jpg",
    },
    verse: {
      label: "<b>올리브나무</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "소망 중에 즐거워하며<br />환난 중에 참으며 기도에 항상 힘쓰며<br /><br /> 로마서 12:12",
    },
    partner: {
      label: "<b>올리브나무 (ISFJ)</b> 와 나의 조화는?",
    },
  },
  estj: {
    type: "ESTJ",
    name: "돌무화과나무(뽕나무)",
    kakao: "'돌무화과나무(뽕나무)'와 나는 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/e293VPzUrrmES_R_NcjwwIk2-pMzP0h5vVqL-mjZUbASwOdQG8HO3M8AtgKzqy-l4z4dOtmdxfoazBTsH0nqG9lLtjpOkSzfK6rC4aaal41TT3RC5904cOZV-atpxuGy9B8F5xWpgQD38ppN9JGdsLdw8-ZEDk6R6ZQndnsZU7viLOBa8H2oKGpRc1BQF-ahWuejG4O0JEpUNlzV8En_ahaUBlTQWo6GR_EIbALMsqnQSw3IGcA6KgwmZEwlT_IfBjY_pNdOQTarm_Z9YPL4eG8-9GMq7i69Or-iTnwCOySNHm9Iix65SbFz39QBPc9d-K_wttUpyajg1J6VMQ1OJDji-5yZqWkRVQ066OCQZj1PQDebgBXvV4GGjdn_Ft35Iok3CS6mH4_x2LcM5-cybVoPHDHpfzUgdcqm2mnygTC7kHoKf9H1Sd0fveamYboaRBT3xDd0hHcokskSBQ6HzgYqXGTP7HkKHJn4OllVrwtAYj8Q5DPodPQ-ccuzRYTwDmgXJQD91r0FegLeCtscfSBIe3-HPzoPs6QXx51BzxZ44qqFtcQfR75mExUJxkfJIW2nUjAmlY3GMQss8abq1cklQ16xD-LMQ0DWpQTfJcTJ7HSkYjviGWRNOC5bofx_KxnNXnXprLKUQYZK6WIIFVdqjiKCyyuVioBXq_GbQPe2kNxW1xOeiOfXJXYeyA=s834-no?authuser=0",
    title: "모두에게 공평히 나누어 주는 관리자",
    description: `삭개오가 올라가 예수님을 만났다는 뽕나무(돌무화과나무)는 길가에 심어진 흔한 나무였지만, 일년에 6회에 걸쳐 열매를 맺기 때문에 가난한 이들에게 중요한 열매 중 하나였답니다. 그늘이 넓고 열매가 많아 많은 백성들에게 사랑을 받고는 했죠.
    이처럼 많은 사람들과 함께하는 돌무화과나무를 닮은 당신은 때로는 엄격하게, 가족이나 사회를 하나로 묶는 역할을 해내기도 한답니다.

    <p>- 외향적이고 감정이 잘 드러나는 경우가 많아요.</p><p>- 리더 역할을 좋아하진 않는데, 막상 맡으면 또 곧 잘해요.</p><p>- 경영자적인 재질, 가지고 있는 것을 적절하게 활용하는데 탁월한 자질을 지녔어요.</p><p>- 경쟁을 싫어하지만, 경쟁에서 지는 것도 싫어요!</p>
    `,
    video: {
      label: "<b>돌무화과나무</b> 와 어울리는 <b>영상</b>",

      title: "김윤진 - 아무것도 두려워말라 + 하나님이시여",
      link: "https://www.youtube.com/embed/j-GYiV68t10?si=af_KeriHs58uVaCm",
    },
    goods: {
      label: "<b>돌무화과나무</b> 에게 꼭 맞는 <b>굿즈</b>",
      title: "위드 지저스 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=3202",
      image:
        "https://loaisloveoneanother.com/web/product/big/202406/3d3ec46a221cb55a314ebc83dac81fa1.jpg",
    },
    verse: {
      label: "<b>돌무화과나무</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "여호와께서 이와 같이 말씀하시기를<br />너희는 정의를 지키며 의를 행하라<br />이는 나의 구원이 가까이 왔고 나의 공의가 나타날 것임이라 하셨도다 <br/><br /> 이사야 56:1",
    },
    partner: {
      label: "<b>돌무화과나무 (ESTJ)</b> 와 나의 조화는?",
    },
  },
  esfj: {
    type: "ESFJ",
    name: "백합화",
    kakao: "'백합화'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/LLnSIqyjNXv2983wBj7pJj1hsQp_GRuiJvwO2xYVDyfDHEF4FvAV_ZvQibuUR-bttjGwPR970NrasF1GpLz33cLrCOJtxciDp_vm0yaSOfRmhY3LT_8fdfzxCXVcG4GBqf1iXFXfEMffrhBmpRKSh6pOu485qA6_doUCPOR0Kd6FUrCkbSfXd6RM94KokBZuNlZmDh-oRPTEZfpEmG_UjyFd0EL9oCpaNY2cRGqqofzLb_h9kDcUwt8NXXpKKGccYSIaudVyEcrlQ2wWeI9uw2izqVLnzOR2X2wNGIywIDnX7g25se87xXjeCbQY5T7r-RG0_BWl5txfPt4nAAMystc3yiq1Ag2mihMQqOlZ8iDxwfzX1rCS11dlxU_nM_mcjWxyLUilizHTpAM8HS5CSJ5uv_eLjcSYBotHUklqpQjcZfI5AzYd0QUzWBntLJyIokJkvm4s3f2m4XZ25WRsmqfaZkPetvCTXHBQGRUIYZpbOhwbGUatIPpHo8TjPbsoQbOFl03XMiD4LFGLudFfZTlyrpMF_c0O4fwdLwJmG8ldteRJazBbKDTwlQdHfgjPtrz5jqbGuFXj2rkJ520aexZ1l0AEzNJjjYFUWDAstaUOaeLY_24pxfq82qCBpxSd39y55Yw4Ob3c3xzK8EwedswlF6_pIUvB2BUSg2Tr1cVQB7r5Sdd1AfUbTJizbg=s834-no?authuser=0",

    title: "어디에나 어울리는 사교적인 꽃 ",
    description: `솔로몬의 옷보다 아름답다는 백합화!
    우리가 알고있는 백합은 하얀색이지만, 성경속 지역에서의 백합화는 대부분 붉은색에 양귀비 꽃을 닮았다는 점 알고 계신가요?
    하나님의 돌보심을 받는 유명한 식물인 백합화는 '들의 백합화를 보라' 라고 언급될 정도로 흔하며, 여러 곳에 조화롭게 뿌리내려 있는 식물이었답니다.
    들판을 수놓은 붉은 백합화 꽃밭을 상상해보세요. 사교적인 당신과 꼭 닮았을 거예요.

    <p>- 스트레스를 받으면, 누군가를 만나야해요.</p><p>- 준비성이 철저하며, 참을성이 많고 타인을 잘 도와요.</p><p>- 타인에게 인정을 받는것에 민감해요.</p><p>- 사람을 잘 싫어하지 않아요. 행복도가 매우 높은 유형이에요.</p>
    `,
    video: {
      label: "<b>백합화</b> 와 어울리는 <b>영상</b>",

      title: "kei is loved - 세상에 치이셨나요?",
      link: "https://www.youtube.com/embed/PRQ3dREdygo?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>백합화</b> 에게 꼭 맞는 <b>굿즈</b>",
      title: "감사의 마음 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2720",
      image:
        "https://loaisloveoneanother.com/web/product/big/202311/2a139ba53b9f4c3fbbfad1ca3f28f00a.jpg",
    },
    verse: {
      label: "<b>백합화</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "우리가 알거니와 하나님을 사랑하는 자들에게는<br />모든 것을 협력하여 선을 이루느니라<br /><br />로마서 8:28",
    },
    partner: {
      label: "<b>백합화 (ESFJ)</b> 와 나의 조화는?",
    },
  },
  istp: {
    type: "ISTP",
    name: "백향목",
    kakao: "'백향목'과 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/h_Oy1aAc0OByKguSMYAO-z2PT1_XWE0rpSeyHVGowT6fCAlyV36XwFPYqFrwcGXUKt0Hhm1S41C9KZnQXBDl3rvTKGb3kZchxBfDo50thqskb1dC3BlPvYM212-HT6LQYPP3bY1-dYpnNyZNiHr2F4s8wRcN-kewUWG36jDhniGhDfLnC8E3zhvdPaobpimmIqHARweHZeEG0L-ez0XhmA_h3V_CKXYIZVz6KLUwH2K_HVa4fyCtoT4woaO44-dLWzn9o4bKePNN4jtC4GBNI9w869yPlU-_mXGdO92gEd-dLYj_W7-cjJg3WmRDWm0Mp97sc8WPZo229dfWItNbTHNKzKjpIPr0wIzCOSQ5TvYZ7l6q06bSc7YGKVPESDq6Mmo19DeVkf21vvzgR5tANylJww4SyS1kYX7F-gy5VPRN0SWzn6EMhZEVFKiYvqOUJXEuVokx_wySZnX_S-JE96RA2SEa5gJ1iH-dTZNW9stntfOaeLDnHvA7LB055aGMafXboeBt9hD-DiaVy2ZTM7m1xqIa_FY2JUIozwraJhBunlsXouMSjhKdhpM9AXfsLiGU097rgB223QWcpoYXuhooEjetWR-_hslW6xy4BH3WK6CmokeGQmTSypWlogcwDI7K8rKnlfxMDQE5HLTJJdkgzD2hzOiwKUoMdh-fYfK4b2NENKGtQLq6GW7MZA=w834-h835-no?authuser=0",

    title: "어디에 쓰임받을까? 만능블럭 그자체! ",
    description: `백향목은 성경에서 가장 중요한 나무 중 하나로, 성전을 짓는데도 사용되었습니다.
    백향목은 기온이 낮은 고산지대에서 북풍과 설한을 이겨내며 자라납니다. 세월이 흘러도 향기를 잃지 않고, 오히려 더욱 견고해지는 백향목은 시 104:16에서 언급된 것처럼 그리스도인의 영원한 성장과 번성을 상징합니다.
    백향목을 닮은 당신은 여러군데에 쓰일 곳이 많은 대기만성형 성장 가능성을 가진 만능재주꾼이라고 할 수 있습니다.

    <p>- 어떤 일을 하든 노력에 비해 효율이 높은 편이예요.</p><p>- 고정관념이나 편견이 없는 편이에요.</p><p>- 자신의 일에 고집이 있고 주장이 강한 편, 하지만 타인에게 간섭하지는 않아요.</p><p>- 상대의 감정이나 생각은 잘 읽지만 눈치를 보며 행동하지는 않아요.</p>
    `,
    video: {
      label: "<b>백향목</b> 과 어울리는 <b>영상</b>",

      title: "어노인팅 - 삼위의 하나님 사랑이",
      link: "https://www.youtube.com/embed/zPOIwD_tMGc?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>백향목</b> 에게 꼭 맞는 <b>굿즈</b>",

      title: "감사 습관 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2661",
      image:
        "https://loaisloveoneanother.com/web/product/big/202311/eb12a40ce6d34e8d7533eb390ab92f0f.jpg",
    },
    verse: {
      label: "<b>백향목</b> 이 묵상해볼만한 <b>말씀</b>",
      title:
        "지혜가 너를 선한 자의 길로 행하게 하며<br />또 의인의 길을 지키게 하리니 <br/><br /> 잠언 2장 20절",
    },
    partner: {
      label: "<b>백향목 (ISTP)</b> 와 나의 조화는?",
    },
  },
  isfp: {
    type: "ISFP",
    name: "석류",
    kakao: "'석류'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/jYNdcIFF8PzGgjKzr3ZHzLmIUpGW81L301hEuZ0T68WyGX9jPBSSuVmVeAFDDmyjwHYy3l1u47iEmSnC2SQz6dGx_VP5xJ_j7Zp1vcqfhb2FAJi3V48HCYhn-v9wO857MdhFt9lgvbuHvLVcmdB5GREdxMrNr96UuZ6jbgPsHqlPLvLANaPVqcdjUf6ZwB3K_Ei14xTwQRbxp0NgnSZzKTmfqB4OSN6pKAFygvavIPsa_4J5gakElYJVma9VmFnQwGuUTZkhpubCAGke8U2b97ZxoCM39il-ReTp7Xtz9FSyZwoEP4PHhxBunSX21IgfYDoKu8cLUz5ZCsswjxQHx8sRVCAVSnemjqhPTCg31fipPKXWT-DlVtF39B9XrSfx73tlL8XK3ZwUA5-QT3lg3IT-m-Q3Un0pZqzqoa5iV6VkAjbNFBReoGBXLAz9IPph-KVrVEtMCXA7R1VQkxAIcHeIPgBaPTvXgNNzh_f836XLFGt69THuJZ1j7_WpRWEVYGpLlWy8AiccrZE-vE0ZotzIbqhzrVDWtPb7ZislpSnLH8usvDq95opzgGDG97y9WHIuj600dbGl-x62rxPDRhqR6WXvw5hmtAYM91aaI681lOIYaX7AuyjRDgXiO3mGklUOfadNLOIqCrioauwovMa9ICWREmUSgD-LzfSODZ3LZNfHXiIcU8EBPwNBnA=s834-no?authuser=0",

    title: "보석과도 같은 영감을 품은 열매!",
    description: `열매 안 쪽의 씨앗이 촘촘히 박힌 붉은 보석 같아 보이는 석류는 풍요와 영광, 부활, 아름다움 등을 상징한답니다.
    또 석류는 출애굽 시대의 예술작품이라고도 할 수 있는 대제사장의 의복에 수놓아지거나(출 28:33),  보티첼리와 같은 화가들의 그림에도 소재로 사용되는 등 그 아름다움을 유감없이 뽐내기도 했죠.
    많은 예술가들에게 영감을 줘왔던 석류. 그 안의 보석과도 같은 씨앗들은 마치 당신이 가지고 있는 빛나는 무언가를 닮았어요!

    <p>- 호기심이 많고, 타인을 잘 이해해주고 많이 맞춰줘요.</p><p>- 즐거움을 추구하며, 문화 생활을 좋아해요. 예술적 감각도 꽤 있는 편!</p><p>- 규칙 또는 틀에 묶이는 것을 좋아하지 않아요.</p><p>- 자연적인 것, 목가적인 것, 전원적인 것에 관심을 가질 가능성이 높아요.</p>
    `,
    video: {
      label: "<b>석류</b> 와 어울리는 <b>영상</b>",

      title: "김상진, 짐니 - Way Maker (길을 만드시는 주)",
      link: "https://www.youtube.com/embed/QBONAHsfu8Q?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>석류</b> 에게 꼭 맞는 <b>굿즈</b>",

      title: "MZ저격 세트",
      link:
        "https://loaisloveoneanother.com/product/mz-%EC%A0%80%EA%B2%A9-%EC%84%B8%ED%8A%B8/3229/category/100/display/1/",
      image:
        "https://loaisloveoneanother.com/web/product/big/202407/b6a5c39e9484e63f3a23d07e009e2c75.jpg",
    },
    verse: {
      label: "<b>석류</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "너는 내게 부르짖으라 내가 네게 응답하겠고<br/>네가 알지 못하는 크고 은밀한 일을 네게 보이리라.<br/><br /> 예레미야 33장 3절",
    },
    partner: {
      label: "<b>석류 (ISFP)</b> 와 나의 조화는?",
    },
  },
  estp: {
    type: "ESTP",
    name: "겨자씨",
    kakao: "'겨자씨'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/BqBlOZ5rsjsyrdbm5m4L_KHKouozwPcT_Py76_Irf81zZJJi6CAXUi3hDL8IIym-Ds9UGneIeg_W1EhYpbwiHEMxBTKYHx0ZLl6aVkEDdqz9s1BVYRQhmHZrbR0Ns_KWkmpyOS8TzXQGLWFsMfY1W1qWWCaOki9ARQSRU8fGwaz5Ccxqi0Jip98tzEpmrnSbbPqKujgt7r7cyXefCNqO8-J0KSvFW8iamCoarVAcuDftN-zD3GCRbCa_ejONBSL9zJTMnvl4R7m8O2eMEGXQJmUBDG73BpDH6dgiKe-RjVvhHGAw7CLy_rNnIP0byqic7P3Y9AAWWDy52rech8FwFhrIsxe4RsFM8EWiDur92rqwwngBPKpC-qdu1dDlJq9X1PIKRl5MG_ngp3ut3LZHtysw1InXYOVXVmUfQveu5lyYzZ6u-exRuK2Bo2BOrQBYqBb24WOwUlYbqhWtyOa1Pm_5aNcXBAOxMYdF12Fy58Ra5wlt9Vzpjs4P7ySZIK1KP2a7J0oIyfbEhwt1RriSCf-s8xtqGfcjj9OsaFbvzZ_-o9BF451bxXdNPD3ZfZs504I2KSQyOTjh3Q-LvHsTKXlQojZ9OBUbPju30_9lKYbSG11vIADNlh2kdSYMPT_gQzDNxuGLTG8knzQQtEigTPhSkatd7jRKtTO6wIIOWN1ve_fvVZsqs6B_HikJhw=w834-h835-no?authuser=0",

    title: "작은 가능성으로도 큰 소출을 맺는 모험가",
    description: `겨자씨, 어쩜 작을진 몰라도 누구보다 큰 가능성을 가지고 있는 당신!
    산을 옮길만한 모험을 즐기는 사업가형인 당신은 센스있고 유머러스한 팔방미인일 확률이 매우 높습니다!
    충분한 양의 시행착오를 경험하며 성장을 거듭한다면, 분명 멋진 리더가 될 자질이 있습니다!
    (참, 주변 사람들이 당신이 리더가 되는 상황을 원할지도요)

    <p>- 말보다 행동이 먼저 나가며, 꽤 스릴을 즐기는 편!</p><p>- 묶여있지 않은 자유로운 상태일 때, 일의 능률이 높아요.</p><p>- 현실적인 계산이 눈에 보일 때가 많아요.</p><p>- 금손들이 많은 유형이에요!</p>
    `,
    video: {
      label: "<b>겨자씨</b> 와 어울리는 <b>영상</b>",
      title: "1인 4역, 신학자 별 코로나 입장 순삭 정리",
      link: "https://www.youtube.com/embed/W3feJQGWkM8?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>겨자씨</b> 에게 꼭 맞는 <b>굿즈</b>",

      title: "내 길의 빛",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2238",
      image:
        "https://loaisloveoneanother.com/web/product/big/202304/8a7da0ca7d9c36282837b1045309af50.jpg",
    },
    verse: {
      label: "<b>겨자씨</b> 가 묵상해볼만한 <b>말씀</b>",
      title:
        "내가 주를 의뢰하고 적진으로 달리며<br/> 내 하나님을 의지하고 성벽을 뛰어넘나이다.<br/><br/> 사무엘하 22:30",
    },
    partner: {
      label: "<b>겨자씨 (ESTP)</b> 와 나의 조화는?",
    },
  },
  esfp: {
    type: "ESFP",
    name: "포도나무",
    kakao: "'포도나무'와 나는 얼마나 잘 어울릴까요?",
    kakaoThumb:
      "https://lh3.googleusercontent.com/ItISgVKy5YTubRhbZr8mN9tRn1nJ7yU6wOc7QQ0GwJfgKQPTzUaQy5lv9bp30UEVFSVJ6Pl2OC54Ef7WgrXHnlBAw57c0ilaajDJ1KwNDthuasG8ngdwxtTORMOwcb8KVqmyBA6aIEMM9hwPFX1hBLezYfkBhFnGxwSWGgsdFBKPhEGozW-qhE2dHsda4-E_j0ywAy3KBN_wMwO3HMSwxfkrdt9Nc3mO62SRS6GY5UdFB5uK4nPCGHLNZpQgV5kGCGtjjvbHWelFW8uFWxVrkm1s0sPAFB8J0egSbXVMBFkuBPjRBsIWIMSZTWNIZTI8vdd4QcBZ-Dm9zL9qF3F5PhhMR7w3atVhLjJIc_1J1hYOOVAND_zapD6laKDKQAyTNj3hGFii3nR4rugvb1GbW9hJPepPoDwCXzFEZoaw4gVYbAG7RAPSE2zfjgSrGnMGn1fdzHAwu9Qn2J1p4D5ql4Zbz5SXpEDEuIJz8BZXa4BOK3L8mvJ4J6tebCOGkOQ0uXRQ7taRUzgtr5E_3ZkC6angc-xvBrInx3IK7ixI9s2TRGiJHUHlD7O2Wl-E07hBRnK3A5bZwQaBVlmCxBuF5yzS8QG7Nmp7nSY840Dzk9Aqn2XquMcWsVak2SgKTVM47AnA8HgWHXUwSslbIKVPaTDrQGKGfshQlABG8_D3dEOWpfOynK4vjFyiddldjw=w834-h835-no?authuser=0",

    title: "모두를 행복하게 하는 화려한 열매! ",
    description: `포도나무가 얼마나 이스라엘 백성들을 행복하게 만들어 주었는지 아세요? 늦여름인 9월에 수확하는 포도열매는 먹을 것이 부족한 시기에 그야말로 축복과 같은 열매였답니다.
    또 포도주는 어떻게요, 가나의 혼인잔치나 최후의 만찬과 같은 중요한 장소에 빼놓을 수 없는 인싸 식품이었다는 점!
    열매도 크고 화려하기에 인기도 많았던 중동의 포도나무, 포도나무와 같이 멋진 당신은 가는 곳마다 파티를 일으키는 파티피플 일수도?!

    <p>- 모든 유형 중, 외향적인 성격이 가장 강한 편이에요!</p><p>- 자신의 이야기를 다른 사람에게 잘 털어놓는 편.</p><p>- 하고 싶은 말은 많은데 정작 하고 싶은 말은 못할 때가 많아요.</p><p>- 타인을 기쁘게 해줄 깜짝쇼를 준비하면서 즐거워해요.</p>
    `,
    video: {
      label: "<b>포도나무</b> 와 어울리는 <b>영상</b>",
      title:
        "퉁이가 읽는 성경 5탄<br/>다윗이 중2병인 상태로 골리앗과 싸운다면?",
      link: "https://www.youtube.com/embed/eESqQJDUQk8?si=PkcHpHIpV9ZyoLwl",
    },
    goods: {
      label: "<b>포도나무</b> 에게 꼭 맞는 <b>굿즈</b>",
      title: "여행을 떠나요 세트",
      link:
        "https://loaisloveoneanother.com/product/detail.html?product_no=2925",
      image:
        "https://loaisloveoneanother.com/web/product/big/202402/26cfea19c0124415e79a4d782d7c1516.jpg",
    },
    verse: {
      label: "<b>포도나무</b> 가 묵상해볼만한 <b>말씀</b>",

      title:
        "할렐루야 우리 하나님께 찬양함이 선함이여<br/> 찬송함이 아름답고 마땅하도다 <br/><br/> 시편 147:1",
    },
    partner: {
      label: "<b>포도나무 (ESFP)</b> 와 나의 조화는?",
    },
  },
};

export default result;
