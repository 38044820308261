<template>
  <div class="test">
    <div class="card">
      <img src="@/assets/test-card.png" alt="" />
      <div class="card-desc">
        <strong>{{ stepUserView }}</strong>
        <div class="progress-bar">
          <span :style="{ width: progress + '%' }"></span>
        </div>
        <div class="question-box">
          <span :class="[{ next }, `step-${step + 1}`]"></span>
          <p v-html="current.question"></p>
        </div>
      </div>
      <div class="answer-box">
        <ul>
          <li v-for="ans in current.answer" :key="`${current.id}_${ans.code}`">
            <button
              @click="selectAnswer(current.id, ans.code, current.type)"
              v-html="ans.text"
            ></button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import list from "../test/list";
export default {
  name: "Test",
  data() {
    return {
      step: 0,
      list,
      type1: {
        i: 0,
        e: 0,
      },
      type2: {
        n: 0,
        s: 0,
      },
      type3: {
        t: 0,
        f: 0,
      },
      type4: {
        p: 0,
        j: 0,
      },
      result: "",
      next: false,
    };
  },
  methods: {
    selectAnswer(_, code, type) {
      this.$gtag.event(`Test/${this.step}/${this.code}`);

      if (type !== "fake") {
        this[`type${type}`][code] += 1;
      }

      if (this.step == 15) {
        this.getResult();
      } else {
        this.next = true;
        setTimeout(() => {
          this.next = false;
        }, 200);
        this.step += 1;
      }
    },
    getResult() {
      const {
        type1: { i, e },
        type2: { n, s },
        type3: { t, f },
        type4: { j, p },
      } = this;
      this.result = `${i < e ? "e" : "i"}${n < s ? "s" : "n"}${
        t < f ? "f" : "t"
      }${j < p ? "p" : "j"}`;
      this.$store.state.type = this.result;
      this.$router.push("/loading");
    },
  },
  computed: {
    stepUserView() {
      return this.step > 8 ? this.step + 1 : `0${this.step + 1}`;
    },
    current() {
      return this.list[this.step];
    },
    progress() {
      return (100 / list.length) * (this.step + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  background: url(~@/assets/test_bg.png) no-repeat center;
  overflow: hidden;
  max-width: 450px;
  margin: 0 auto;
  min-height: 100vh;
  .card {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    img {
      width: 100%;
    }
    .card-desc {
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 32%;
      width: 100%;
      text-align: center;
      strong {
        font-size: 30px;
        display: block;
        margin-bottom: 10px;
      }
      .progress-bar {
        background: #eee7d9;
        height: 5px;
        position: relative;
        width: 200px;
        margin: 0 auto;
        overflow: hidden;
        span {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 10%;
          background: #e05730;
          height: 100%;
          transition: all 0.2s ease;
        }
      }
    }
    .question-box {
      padding-top: 70px;
      width: 80%;
      text-align: center;
      margin: 0 auto;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      position: relative;
      p {
        font-size: 17px;
        line-height: 25px;
      }
      span {
        position: absolute;
        display: block;
        left: 50%;
        top: 35px;
        transform: translate(-50%, 0);
        width: 35px;
        height: 30px;
        transition: all 0.5s;
        &.step-1 {
          background: url(~@/assets/icon-1.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-2 {
          background: url(~@/assets/icon-2.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-3 {
          background: url(~@/assets/icon-3.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-4 {
          background: url(~@/assets/icon-4.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-5 {
          background: url(~@/assets/icon-5.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-6 {
          background: url(~@/assets/icon-6.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-7 {
          background: url(~@/assets/icon-7.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-8 {
          background: url(~@/assets/icon-8.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-9 {
          background: url(~@/assets/icon-9.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-10 {
          background: url(~@/assets/icon-10.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-11 {
          background: url(~@/assets/icon-11.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-12 {
          background: url(~@/assets/icon-12.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-13 {
          background: url(~@/assets/icon-13.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-14 {
          background: url(~@/assets/icon-14.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-15 {
          background: url(~@/assets/icon-14.png) no-repeat center bottom;
          background-size: cover;
        }
        &.step-16 {
          background: url(~@/assets/icon-14.png) no-repeat center bottom;
          background-size: cover;
        }
        /* transition: all 0.2s ease;
				&.next {
					transform: translate(-50%, 0) scale(0.9);
				} */
      }
    }
    .answer-box {
      margin: 0 auto;
      position: absolute;
      bottom: 15%;
      left: 50%;
      width: 80%;

      height: 240px;
      transform: translate(-50%, 0);
      ul {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0, -50%);
        padding: 0 20px;
      }
      button {
        border: 1px solid #2a2a2a;
        min-height: 50px;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        display: block;
        border-radius: 10px;
        width: 100%;
        background: transparent;
        padding: 5px 10px;
        margin-bottom: 10px;
        word-break: keep-all;
        cursor: pointer;
        background: #fff;
        box-shadow: 2px 1px 2px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
</style>
