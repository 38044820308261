const list = [
  {
    id: 1,
    type: "fake",
    question: "요즘 내가 부르거나<br/> 듣는 찬양은 어떤 유형?",
    answer: [
      {
        text: "위러브, 카우치 워십 등 최신 찬양 혹은 외국 찬양 (최신 CCM)",
        code: "a",
      },
      {
        text: "이 땅의 황무함을 보소서! (복음성가)",
        code: "b",
      },
      {
        text: "내게 강~ 같은 평화! 내게 강~ 같은 평화! (찬송가)",
        code: "c",
      },
      {
        text: "교회에서 정한 콘티대로 부른다.",
        code: "d",
      },
    ],
  },
  {
    id: 2,
    type: "fake",
    question: "나의 신앙생활에<br/> 가장 도움이 되는 매체는?",
    answer: [
      {
        text: "QT책 등 성경 본문이 있는 도구",
        code: "a",
      },
      {
        text: "신앙 서적",
        code: "b",
      },
      {
        text: "유튜브, 인스타그램, 줌 등 SNS채널",
        code: "c",
      },
      {
        text: "레디컬과 같은 페스티벌, 혹은 찬양집회를 통해",
        code: "d",
      },
    ],
  },
  {
    id: 3,
    type: 2,

    question:
      "만약 (새신자를 위해)<br />예수님에 대한 책을 집필해본다면<br/>첫줄은?",
    answer: [
      {
        code: "s",
        text: "예수 그리스도 하나님의 아들이십니다. 그분은 동정녀에게 나시고……",
      },
      {
        code: "n",
        text: "지금부터 저의 삶에 찾아오신 예수님을 소개할게요. 그분은…….",
      },
    ],
  },
  {
    id: 4,
    type: 1,
    question: "공동체에 새가족을 초대하기로 했다.<br/>내가 하고 싶은 역할은?",
    answer: [
      {
        code: "e",
        text: '"안녕하세요, 처음오셨죠?" 소그룹에서 말을 거는 도우미 역할.',
      },
      {
        code: "i",
        text: '"꽃장식은 잘 달려있나?" 장내를 화사하게 만드는 미화 역할',
      },
    ],
  },
  {
    id: 5,
    type: 3,
    question: "새신자용 선물을<br/> 골라본다면 나는?",
    answer: [
      {
        code: "t",
        text: "그 사람에게 실질적으로 필요한 것을 준비하거나, 물어본다.",
      },
      {
        code: "f",
        text:
          "성경책, 교회 다이어리, 말씀 머그컵 등 신앙생활에 도움이 될만한 물건.",
      },
    ],
  },
  {
    id: 6,
    type: 1,
    question: "정규 예배가 끝났을 때<br/> 나의 모습은?",
    answer: [
      {
        code: "e",
        text: '"어이구, 김 집사님! 아이고, 박 청년!" 반가운 사람들과 인사한다.',
      },
      {
        code: "i",
        text: "인사는 짧게! 집에가거나 잽싸게 다음 봉사부서 활동을 준비한다.",
      },
    ],
  },
  {
    id: 7,
    type: 3,
    question:
      "주중 야근을 앞두고 있을 때,<br/> 힘들어하는 형제/자매에게<br/> 연락이 왔다.",
    answer: [
      {
        code: "t",
        text:
          '일단 어떤 일인지 물어보고, 시급한 일이 아니라면 "주일에 시간을 내서 이야기하자."라고 말한다.',
      },
      {
        code: "f",
        text:
          '"나 오늘 야근이라 11시에 끝나거든?ㅠㅠㅠㅠ 일단 만나서 얘기해 ㅠㅠㅠㅠㅠㅠㅠ."',
      },
    ],
  },
  {
    id: 8,
    type: 3,
    question: "단기 선교, 봉사활동 등을 갔을 때,<br/> 더 뿌듯한 상황은?",
    answer: [
      {
        code: "f",
        text: "현지 아이들의 눈에 눈물이 고이며<br/>내게 고맙다고 얘기할 때.",
      },
      {
        code: "t",
        text:
          "우리가 2박 3일간 판 우물 덕에 <br/>실제로 주민들의 위생상태가 매우 좋아졌을 때.",
      },
    ],
  },
  {
    id: 9,
    type: 4,
    question: "교회에서<br/> MT장소 답사를 가게 되었다.<br/> 나는?",
    answer: [
      {
        code: "j",
        text:
          "따뜻한 물은? 병원이나 마트랑은 가까울까? <br/>답사의 본래 목적에 충실한 것이 좋다.",
      },
      {
        code: "p",
        text: "답사도 하나의 여행이다.<br/>휴게소 맛집 리스트를 먼저 찾아본다.",
      },
    ],
  },
  {
    id: 10,
    type: 4,
    question: "공동체 내에<br/>새가족을 초대하기로 했다.<br/> 나는?",
    answer: [
      {
        code: "p",
        text:
          '초대장보다는 말로 더 설득한다. <br/>"우리 교회 사람들 진짜 좋으니까 일단 한 번 와봐!"',
      },
      {
        code: "j",
        text: "일시, 장소, 시간표, 행사 등을 자세히 적은 초대장을 만든다.",
      },
    ],
  },
  {
    id: 11,
    type: 1,
    question: "지치고 힘들 때는 어떻게 하나요?",
    answer: [
      {
        code: "e",
        text: "주님이 보내신 사람들을 통해 힘듦을 잊는다.",
      },
      {
        code: "i",
        text: "혼자 있거나, 주님과 단 둘의 시간을 보내고 싶다.",
      },
    ],
  },
  {
    id: 12,
    type: 2,
    question: "요즘 신앙생활에 있어서<br/> 나의 관심사는?",
    answer: [
      {
        code: "s",
        text: "요즈음 교회의 사회적인 위치나, <br/>때론 신학적인 주제에 대해.",
      },
      {
        code: "n",
        text:
          "하나님 안에서 내가 약해진 것은 아닐까? <br/>공동체 안에서 나의 역할은 무엇일까? 등에 대해.",
      },
    ],
  },
  {
    id: 13,
    type: 2,
    question: "오늘은 교회 대청소 날,<br/> 나는?",
    answer: [
      {
        code: "s",
        text: "역할에 충실히. 주어진 도구로 청소에 열중한다.",
      },
      {
        code: "n",
        text:
          '"이렇게 하면 더 효율적일 것 같은데?"<br/>주로 전체적인 방법에 대해 아이디어가 떠오른다.',
      },
    ],
  },
  {
    id: 14,
    type: 4,
    question: "교회 수련회 두번째 날 밤,<br/> 마지막 날을 앞둔 나의 모습은?",
    answer: [
      {
        code: "j",
        text: "수련회가 일정대로 진행되는게 중요하다. 내일을 위해 일찍 잔다.",
      },
      {
        code: "p",
        text: '"친해지는 것도 수련회의 중요한 일정이지!" 수다를 떤다.',
      },
    ],
  },
  {
    id: 15,
    type: "fake",
    question:
      "<span style='font-size:14px; line-height: 16px'>10월 3일 개천절에 국내 최대규모<br />크리스천 페스티벌 READYCALL (레디컬)<br/>이 열린다고 한다. 나라면 ?</span>",
    answer: [
      {
        text: "들어본 적이 없지만 궁금하다.",
        code: "a",
      },
      {
        text: "이미 사전신청을 했고, 혼자서 조용히 다녀올 생각이다.",
        code: "b",
      },
      {
        text: "친구들과 함께 가서 위러브 저녁집회까지 함께할 생각이다.",
        code: "c",
      },
      {
        text: "전도하고 싶던 친구들과 함께 가서 자연스러운 전도를 할 계획이다.",
        code: "d",
      },
    ],
  },
  {
    id: 16,
    type: "fake",
    question:
      "<span style='font-size: 14px;'>선한 사마리아인처럼, 내가 가진것들을 통해<br />남을 도와본 경험이 있나요?</span>",
    answer: [
      {
        text: "정기적인 후원을 하는 단체가 있다",
        code: "a",
      },
      {
        text: "가끔 생각나거나  일회성으로 돕는다.",
        code: "b",
      },
      {
        text: "마음은 있지만 아직 실천을 못해봤다ㅠㅠ",
        code: "c",
      },
    ],
  },
];

export default list;
